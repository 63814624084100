import { formatLastUpdated } from "../../../../../utils/tableUtil";

/**
 * Data normalizer to prepare field.values with transform values. This practice allows filtering
 * to be effective in calculated values
 * @param  {Array} dataset                dataset to map
 * @param  {string} timezone               time zone code
 * @return {Array}          normalized array
 */
export const dataNormalizer = (dataset, timezone) => {
  return dataset.map((record) => {
    const normalRecord = { ...record };
    if (normalRecord.lastUpdated) {
      normalRecord.lastUpdated = formatLastUpdated(normalRecord, timezone);
    }

    return normalRecord;
  });
};
