import { useState } from "react";
import { Select, InputLabel, MenuItem, FormControl } from "@mui/material";
import useCss from "../../hooks/useCss";
import styles from "./styles";

const SbuxSelect = ({
  menuItems,
  value,
  defaultValue,
  handleSelectChange,
  disabled = false,
  maxWidth = null,
  className,
  label,
  required,
}) => {
  const classes = useCss(styles);
  const [localValue, setLocalValue] = useState(defaultValue || "");

  const isControlled = value !== undefined && handleSelectChange !== undefined;

  const handleOnChange = (event) => {
    const newValue = event.target.value;
    if (!isControlled) {
      setLocalValue(newValue);
    }

    handleSelectChange(newValue);
  };

  const getMenuItems = () => {
    return (
      menuItems &&
      menuItems.length > 0 &&
      menuItems.map((menuItem, index) => (
        <MenuItem
          sx={{
            fontSize: 14,
          }}
          key={index}
          className={classes.menuItem}
          value={menuItem.value}
        >
          {menuItem.name}
        </MenuItem>
      ))
    );
  };
  const inputLabel = !!label ? (
    <InputLabel shrink>{label}</InputLabel>
  ) : undefined;

  return (
    <FormControl required={required} size="small" fullWidth>
      {inputLabel}
      <Select
        sx={{ maxHeight: 31 }}
        value={isControlled ? value : localValue}
        onChange={handleOnChange}
        disabled={disabled}
        className={className}
      >
        {getMenuItems()}
      </Select>
    </FormControl>
  );
};

export default SbuxSelect;
