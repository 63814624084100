import React, { useState, useEffect, useRef } from "react";
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Popover,
  Stack,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import DatePicker from "react-datepicker";
import { validateForm } from "../../utils/formValidation";
import SbuxTextField from "../SubxTextField";
import SbuxSelect from "../SbuxSelect";
import SbuxTimeDuration from "../SbuxTimeDuration";
import { LightTooltip } from "./styled";
import {
  transformFilterValueSpec,
  buildFilterSpec,
  operatorsFor,
  normalizeFormControls,
  flatmapOperators,
} from "./util";
import styles from "./styles";
import useCss from "../../hooks/useCss";

/**
 * FilterForm component
 */
const FilterForm = ({
  config,
  handleClose,
  onApplyFilters,
  open,
  withFormControls = {},
  anchorEl,
}) => {
  const classes = useCss(styles);
  const [filterList, setFilterList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();
  const [formControls, setFormControls] = useState(withFormControls);
  const [formValidity, setFormValidity] = useState({});

  useEffect(() => {
    const { items } = config;
    const { filterList, requiredFormControls } = normalizeFormControls(
      formControls,
      items
    );

    setFilterList(filterList);
    setFormControls({
      ...formControls,
      ...requiredFormControls,
    });
  }, []);

  const addFormControlState = (item) => {
    const { name } = item;

    if (!!formControls[name]) {
      return;
    }

    const newFormControls = {
      ...formControls,
    };
    newFormControls[name] = { ...item };

    setFormControls(newFormControls);
  };

  /**
   * Remove filter from the form
   */
  const handleRemoveFilter = (name) => {
    if (formControls[name]) {
      const newFormControls = {
        ...formControls,
      };
      delete newFormControls[name];

      setFormControls(newFormControls);
    }
  };

  /**
   * Build form controls dynamically
   */
  const buildFormControls = () => {
    const elmControls = [];

    for (const key in formControls) {
      const {
        type,
        label,
        name,
        value = "",
        op,
        required = false,
      } = formControls[key];

      const operators = operatorsFor(type);
      const defaultOp = op ?? operators[0].value;
      // set default selection
      if (!op) {
        handleFormControlUpdate(name, { op: defaultOp });
      }

      const operatorList = (
        <SbuxSelect
          menuItems={operators}
          defaultValue={defaultOp}
          handleSelectChange={(op) => handleFormControlUpdate(name, { op })}
          required
        />
      );

      const deleteAction = (
        <IconButton
          aria-label="data filters"
          className={!!required ? classes.invisible : undefined}
          onClick={() => {
            !required && handleRemoveFilter(name);
          }}
        >
          {!required ? <ClearIcon /> : <PriorityHighIcon />}
        </IconButton>
      );

      const validatorInfo = !!formValidity[name] ? (
        <LightTooltip title={formValidity[name]}>
          <IconButton
            className={classes.validator}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            aria-label="validator info"
          >
            <PriorityHighIcon />
          </IconButton>
        </LightTooltip>
      ) : undefined;

      const controlLabel = (
        <span>
          {label}{" "}
          {!!required ? (
            <span className={classes.validator}>*</span>
          ) : undefined}
        </span>
      );

      switch (type) {
        case "date": {
          elmControls.push(
            <Box className={classes.row} key={`${key}-${name}-row`}>
              {deleteAction}

              {controlLabel}

              {operatorList}

              <DatePicker
                key={`${key}-${name}`}
                name={name}
                selected={value}
                dateValue={value}
                onChange={(date) =>
                  handleFormControlUpdate(name, { value: date })
                }
                dateFormat="yyyy-MM-dd"
                popperProps={{ strategy: "fixed" }}
                required={required}
              />

              {validatorInfo}
            </Box>
          );

          break;
        }

        case "datetime": {
          elmControls.push(
            <Box className={classes.row} key={`${key}-${name}-row`}>
              {deleteAction}

              {controlLabel}

              {operatorList}

              <DatePicker
                key={`${key}-${name}`}
                name={name}
                className={classes.datePicker}
                selected={value}
                dateValue={value}
                showTimeSelect
                onChange={(date) =>
                  handleFormControlUpdate(name, { value: date })
                }
                timeFormat="HH:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                popperProps={{ strategy: "fixed" }}
                required={required}
              />

              {validatorInfo}
            </Box>
          );

          break;
        }

        case "timestamp": {
          break;
        }

        case "timeDuration": {
          elmControls.push(
            <Box className={classes.row} key={`${key}-${name}-row`}>
              {deleteAction}

              {controlLabel}

              {operatorList}

              <SbuxTimeDuration
                key={`${key}-${name}`}
                config={{
                  name: name,
                  hh: { min: 0 },
                  mm: { min: 0, max: 59 },
                  ss: { min: 0, max: 59 },
                }}
                withValues={value}
                onChange={(event, value) => {
                  handleFormControlUpdate(name, { value });
                }}
              />

              {validatorInfo}
            </Box>
          );
          break;
        }

        case "number":
        default: {
          elmControls.push(
            <Box className={classes.row} key={`${key}-${name}-row`}>
              {deleteAction}

              <span>
                {label}{" "}
                {!!required ? (
                  <span className={classes.validator}>*</span>
                ) : undefined}
              </span>

              {operatorList}

              <SbuxTextField
                key={`${key}-${name}`}
                name={name}
                value={value}
                handleChange={(event) =>
                  handleFormControlUpdate(name, { value: event.target.value })
                }
                required={required}
              />

              {validatorInfo}
            </Box>
          );

          break;
        }
      } // end-switch
    } // end-for

    return elmControls.length > 0 ? (
      elmControls
    ) : (
      <Box>No filters selected.</Box>
    );
  }; //buildForm

  /**
   * Update control's attribute with value
   */
  const handleFormControlUpdate = (ctrlName, updatedPart) => {
    const item = { ...formControls[ctrlName], ...updatedPart };
    const allFormControls = {
      ...formControls,
    };
    allFormControls[ctrlName] = item;

    setFormControls(allFormControls);
  };

  /**
   * Add selected filter to the form
   */
  const handleAddFilter = () => {
    if (!!selectedFilter && typeof selectedFilter === "object") {
      addFormControlState(selectedFilter);
    }
  };

  /**
   * Data transform filters spec, calls the callbackFn to notify the parent
   * component and closes the Popover
   */
  const applyFilters = (event) => {
    const filters = {};
    // remove emply filters
    for (const key in formControls) {
      const { op, value } = formControls[key];
      if (!!op && !!value) {
        filters[key] = { ...formControls[key] };
      }
    }
    onApplyFilters(filters);
    handleClose();
  };

  /**
   * Handle submit event of the form
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    const fields = Object.keys(formControls);
    const validation = validateForm(form.current, fields);

    if (!validation.invalid) {
      applyFilters(event);
    } else {
      setFormValidity(validation);
    }
  };

  const form = useRef(null);

  return (
    <Popover
      onClose={handleClose}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box className={classes.container}>
        <Box className={classes.actionTop}>
          <span>Filter List</span>

          <SbuxSelect
            menuItems={filterList}
            handleSelectChange={(selectedFilter) => {
              setSelectedFilter(selectedFilter);
            }}
          />

          <Button variant="contained" size="medium" onClick={handleAddFilter}>
            Add
          </Button>
        </Box>

        <Divider />

        <form
          method="post"
          noValidate
          autoComplete="off"
          ref={form}
          onSubmit={handleSubmit}
        >
          <Box className={classes.container}>{buildFormControls()}</Box>

          <Divider />

          <Box className={`${classes.actionButtons}`}>
            <Button variant="outlined" size="medium" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" size="medium">
              Apply
            </Button>
          </Box>
        </form>
      </Box>
    </Popover>
  );
};

/**
 * SbuxDataFilters component
 */
const SbuxDataFilters = ({ config, onApplyFilters }) => {
  const classes = useCss(styles);
  const [formControls, setFormControls] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [operatorMap, setOperatorMap] = useState({});
  const formControlsRef = useRef({});

  useEffect(() => {
    const flatMap = flatmapOperators();
    setFormControls(formControlsRef.current);

    setOperatorMap(flatMap);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateFormControls = (spec) => {
    formControlsRef.current = spec;
    setFormControls(spec);
  };

  /**
   * Apply filter. Call backend
   */
  const handleApplyFilters = (formControlsSpec) => {
    updateFormControls(formControlsSpec);

    // Notify change
    const filterSpec = buildFilterSpec(formControlsSpec);
    onApplyFilters(filterSpec);
  };

  /**
   * Handle the event that deletes a selected filter and sends request to the
   * backend
   */
  const handleDeleteFilter = (name) => {
    if (formControls[name]) {
      const newFormControls = {
        ...formControls,
      };
      delete newFormControls[name];

      updateFormControls(newFormControls);

      // Notify change
      const filterSpec = buildFilterSpec(newFormControls);
      onApplyFilters(filterSpec);
    }
  };

  /**
   * Clears all filters and call the backend
   */
  const handleClearAllFilters = () => {
    updateFormControls({});
    onApplyFilters([]);
  };

  /**
   * Build filter list to render
   */
  const BuildFilterList = (props) => {
    const chipList = [];

    for (const key in props.controls) {
      const {
        name,
        value,
        op,
        label,
        type,
        required = false,
      } = props.controls[key];
      chipList.push(
        <LightTooltip
          key={`${key}-${name}-tooltip`}
          title={`${label} ${operatorMap[op]} ${transformFilterValueSpec(
            type,
            value,
            true
          )}`}
        >
          <Chip
            label={label}
            onDelete={!required ? () => props.onDeleteFilter(name) : null}
          />
        </LightTooltip>
      );
    }
    return <>{chipList}</>;
  };

  /**
   * Clear all filters Icon
   */
  const ClearAllFilters = (props) => {
    return (
      <>
        {props.hasFilters ? (
          <LightTooltip title="Clear All">
            <IconButton
              // disableFocusRipple
              // disableRipple
              aria-label="clear all data filters"
              onClick={() => {
                props.onClearFilters();
              }}
            >
              <ClearAllIcon color="action" />
            </IconButton>
          </LightTooltip>
        ) : undefined}
      </>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <>
      <Box>
        <Stack direction="row" spacing={1} className={classes.filterBar}>
          <span className="title">Filters</span>
          <IconButton
            // disableFocusRipple
            // disableRipple
            aria-describedby={id}
            aria-label="data filters"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <Badge
              badgeContent={Object.keys(formControls).length}
              color="primary"
              classes={{ badge: classes.badge }}
            >
              <FilterListIcon color="action" />
            </Badge>
          </IconButton>

          <BuildFilterList
            controls={formControls}
            onDeleteFilter={handleDeleteFilter}
          />

          <ClearAllFilters
            hasFilters={Object.keys(formControls).length > 0}
            onClearFilters={handleClearAllFilters}
          />
        </Stack>
      </Box>

      {open && (
        <FilterForm
          config={config}
          open={open}
          handleClose={handleClose}
          onApplyFilters={handleApplyFilters}
          withFormControls={formControls}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
};

export default React.memo(SbuxDataFilters);
