const styles = (theme) => ({
  datagridContainer: {
    height: "69vh",
    width: "100%",
  },
  sbuxLogo: {
    height: 120,
    width: 120,
    maxWidth: 120,
    maxHeight: 120,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "auto",
    },
  },
});

export default styles;
