import { Link } from "@mui/material";

export const getDataGridColumns = (handleAudioDowload, handleVideoDowload) => {
  return [
    {
      headerName: "Start Date/Time",
      field: "startTime",
      minWidth: 170,
      maxWidth: 200,
      sortable: false,
    },
    {
      headerName: "End Date/Time",
      field: "endTime",
      minWidth: 170,
      maxWidth: 200,
      sortable: false,
    },
    {
      headerName: "Agent Name",
      field: "agentName",
      minWidth: 220,
      maxWidth: 270,
      sortable: false,
    },
    {
      headerName: "Conn ID",
      field: "connId",
      minWidth: 140,
      maxWidth: 200,
      sortable: false,
    },
    {
      headerName: "Call Type",
      field: "callType",
      width: 140,
      maxWidth: 200,
      sortable: false,
    },
    {
      headerName: "Inbound Caller #",
      field: "ani",
      width: 150,
      maxWidth: 200,
      sortable: false,
    },
    {
      headerName: "Caller Called #",
      field: "dnis",
      width: 150,
      maxWidth: 200,
      sortable: false,
    },
    {
      headerName: "Call Duration",
      field: "callDuration",
      minWidth: 150,
      maxWidth: 200,
      sortable: false,
    },
    {
      headerName: "Store #",
      field: "storeNumber",
      sortable: false,
    },
    {
      headerName: "Partner #",
      field: "partnerNumber",
      sortable: false,
    },
    {
      headerName: "Ext ID",
      field: "callUUID",
      minWidth: 270,
      maxWidth: 280,
      sortable: false,
    },
    {
      headerName: "Agent Username",
      field: "agentUserName",
      minWidth: 200,
      maxWidth: 260,
      sortable: false,
    },
    {
      headerName: "Video",
      field: "videoURL",
      type: 'actions',
      minWidth: 100,
      renderCell: (cellValues) => {
        return cellValues.value ? (
          <Link
            component="button"
            onClick={() => handleVideoDowload(cellValues.value)}
            sx={{
              outline: "none !important",
              textDecoration: "none",
            }}
          >
            Download
          </Link>
        ) : null;
      },
      sortable: false,
      resizable: false
    },
    {
      headerName: "Audio",
      field: "audioURL",
      type: 'actions',
      minWidth: 100,
      renderCell: (cellValues) => {
        return cellValues.value ? (
          <Link
            component="button"
            onClick={() => handleAudioDowload(cellValues.value)}
            sx={{
              outline: "none !important",
              textDecoration: "none",
            }}
          >
            Download
          </Link>
        ) : null;
      },
      sortable: false,
      resizable: false
    },
  ];
};
