import { createSlice } from "@reduxjs/toolkit";
import {
  retrieveApiData,
  deleteApiData,
  resetApiData,
  postApiData,
} from "../services/connect";

const initialState = {
  status: "idle",
  showLoadingIndicator: false,
  datasets: {},
  errorMessage: "",
};

const connectSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetApiData, (state, action) => {
        const { payload } = action;
        state.status = "idle";
        state.errorMessage = "";
        // Clear up selected datasets
        if (Array.isArray(payload?.datasets)) {
          for (const name of payload.datasets) {
            delete state.datasets[name];
          }
        } else {
          state.datasets = {};
        }
      })
      .addCase(retrieveApiData.pending, (state) => {
        state.status = !state.status.startsWith("delete")
          ? "get:pending"
          : "get:followup:pending";
        state.showLoadingIndicator = true;
      })
      .addCase(retrieveApiData.fulfilled, (state, action) => {
        state.status = "get:success";
        state.showLoadingIndicator = false;
        const {
          meta: {
            arg: {
              queryParams: { apiName },
            },
          },
          payload: { data },
        } = action;

        state.datasets[apiName] = data;
      })
      .addCase(retrieveApiData.rejected, (state, action) => {
        state.status = "get:rejected";
        state.showLoadingIndicator = true;
        const {
          error: { message },
        } = action;
        state.errorMessage = message;
      })
      .addCase(deleteApiData.pending, (state) => {
        state.status = "delete:pending";
        state.showLoadingIndicator = true;
      })
      .addCase(deleteApiData.fulfilled, (state, action) => {
        state.status = "delete:success";
        state.showLoadingIndicator = true;
        const {
          meta: {
            arg: { apiName },
          },
          payload: { data },
        } = action;

        state.datasets[apiName] = data;
      })
      .addCase(deleteApiData.rejected, (state, action) => {
        state.status = "delete:rejected";
        state.showLoadingIndicator = true;
        const {
          error: { message },
        } = action;
        state.errorMessage = message;
      })
      .addCase(postApiData.pending, (state) => {
        state.status = "post:pending";
        state.showLoadingIndicator = true;
      })
      .addCase(postApiData.fulfilled, (state) => {
        state.status = "post:success";
        state.showLoadingIndicator = false;
      })
      .addCase(postApiData.rejected, (state, action) => {
        state.status = "post:rejected";
        state.showLoadingIndicator = true;
        const {
          error: { message },
        } = action;
        state.errorMessage = message;
      });
  },
});

export default connectSlice.reducer;
