import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getStopContactState = (state) => state.stopContact;

export const getStopContactDataSelector = createSelector(
  [getStopContactState],
  (stopContact) => stopContact?.stopContactData
);

export const getStopContactPendingSelector = createSelector(
  [getStopContactState],
  (stopContact) => stopContact?.isSCPending
);

export const getStopContactErrorSelector = createSelector(
  [getStopContactState],
  (stopContact) => stopContact?.isSCError
);

export const getStopContactDeleteSelector = createSelector(
  [getStopContactState],
  (stopContact) => stopContact?.isSCDelete
);

export const getStopContactStatusSelector = createSelector(
  [getStopContactState],
  (stopContact) => stopContact?.status
);
