import { createSlice } from "@reduxjs/toolkit";
import {
  getTableListData,
  getTableMetaData,
  createOrUpdateListData,
} from "../services/dataMgt";

const initialState = {
  tableListStatus: "idle",
  tableListLoading: false,
  metadataIsLoading: false,
  metaConfigData: [],
  tableListData: [],
  isDMPending: false,
  isDMSave: false,
  isDMDelete: false,
  isDMError: false,
};

const dataMgtSlice = createSlice({
  name: "dataMgt",
  initialState,
  reducers: {
    clearState: (state) => {
      state.metaConfigData = [];
      state.tableListData = [];
      state.tableListLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTableListData.pending, (state) => {
        state.tableListStatus = "pending";
        state.tableListLoading = true;
      })
      .addCase(getTableListData.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableListStatus = "success";
        state.tableListLoading = false;
        state.tableListData = payload.tableListData?.data?.data || [];
      })
      .addCase(getTableListData.rejected, (state) => {
        state.tableListStatus = "rejected";
        state.tableListLoading = false;
      })
      .addCase(createOrUpdateListData.pending, (state) => {
        state.isDMPending = true;
        state.isDMSave = false;
        state.isDMError = false;
        state.isDMDelete = false;
      })
      .addCase(createOrUpdateListData.fulfilled, (state, action) => {
        const { payload } = action;
        state.isDMPending = false;
        state.isDMError = false;
        state.isDMSave = payload !== "delete";
        state.isDMDelete = payload === "delete";
      })
      .addCase(createOrUpdateListData.rejected, (state) => {
        state.isDMPending = false;
        state.isDMError = true;
        state.isDMSave = false;
        state.isDMDelete = false;
      })

      // Get Table Metadata
      .addCase(getTableMetaData.pending, (state) => {
        state.tableListStatus = "pending";
        state.metadataIsLoading = true;
        state.metaConfigData = [];
      })
      .addCase(getTableMetaData.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableListStatus = "success";
        state.metadataIsLoading = false;
        state.metaConfigData = payload.headerData?.data || [];
      })
      .addCase(getTableMetaData.rejected, (state) => {
        state.tableListStatus = "rejected";
        state.metadataIsLoading = false;
        state.metaConfigData = [];
      });
  },
});

export const { clearState } = dataMgtSlice.actions;

export default dataMgtSlice.reducer;
