import { createAsyncThunk } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const getMetricsQueue = createAsyncThunk(
  "metrics/getMetricsQueue",
  async (metricsPayload, { rejectWithValue }) => {
    try {
      const response = await awsClientGateway({
        pathTemplate: "/queueMetrics",
        method: "POST",
        body: { ...metricsPayload },
      });
      return response;
    } catch (error) {
      clientLog({
        component: `metrics`,
        message: `Something went wrong in getMetricsQueue API => ${error}`,
      });
      logMessage(
        `metrics`,
        `Something went wrong in getMetricsQueue API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getMetricsQueue api endpoint");
    }
  }
);

export const getMyMetricsData = createAsyncThunk(
  "metrics/getMyMetricsData",
  async (myMetricsPayload, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      const response = await awsClientGateway({
        pathTemplate: `/agentMetrics/${instanceId}`,
        method: "POST",
        body: { ...myMetricsPayload },
      });

      return response;
    } catch (error) {
      clientLog({
        component: `metrics`,
        message: `Something went wrong in getMyMetrics API => ${error}`,
      });
      logMessage(
        `metrics`,
        `Something went wrong in getMyMetrics API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getMyMetrics api endpoint");
    }
  }
);
