import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import styles from "./styles";
import useCss from "../../hooks/useCss";

const SbuxTextField = ({
  value = "",
  name = null,
  variant = "outlined",
  label = null,
  disabled = null,
  placeholder = null,
  isError = null,
  errorMessage = null,
  required = null,
  handleChange,
  displayCounter = false,
  maxLength,
  multiline = false,
  rows = 4,
  readOnly = false,
  ...props
}) => {
  const classes = useCss(styles);
  const inputRef = useRef();

  useEffect(() => {
    if (
      inputRef.current &&
      !readOnly &&
      value !== undefined &&
      value !== null
    ) {
      const cursorPosition =
        inputRef.current.selectionStart || value.toString().length;

      if (document.activeElement !== inputRef.current) {
        inputRef.current.focus();
      }

      requestAnimationFrame(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
      });
    }
  }, [multiline, value, readOnly]);

  const calcStyles = [];
  if (multiline) {
    calcStyles.push(classes.multiline);
  }
  if (readOnly) {
    calcStyles.push(classes.readOnly);
  }
  if (props.className?.trim().length > 0) {
    calcStyles.push(props.className?.trim());
  }

  return (
    <TextField
      {...props}
      inputRef={inputRef}
      label={label}
      variant={variant}
      name={name}
      disabled={disabled}
      value={value || ""}
      className={`${calcStyles.join(" ")}`}
      placeholder={placeholder}
      onChange={(e) => {
        if (!readOnly && handleChange) {
          handleChange(e);
        }
      }}
      inputProps={{
        maxLength,
        readOnly,
      }}
      multiline={multiline}
      rows={multiline ? rows : 1}
      maxRows={multiline ? rows : 1}
      helperText={
        <Box className={classes.holder}>
          {isError && (
            <span style={{ fontSize: 12, flex: 1 }}>{`${errorMessage}`}</span>
          )}
          {!readOnly && displayCounter && (
            <span className={classes.counter}>
              {`${(value || "").toString().length}/${maxLength}`}
            </span>
          )}
        </Box>
      }
      error={isError}
      required={required ? required : null}
    />
  );
};

export default SbuxTextField;
