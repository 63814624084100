import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SbuxStyledTabelSortLabel from "./SbuxStyledTableSortLabel";
import SbuxTranslate from "../SbuxTranslate";
import SbuxStyledTableCell from "./SbuxStyledTableCell";
import { visuallyHidden } from "@mui/utils";
import Checkbox from "@mui/material/Checkbox";
import styles from "./styles";
import useCss from "../../hooks/useCss";

const SbuxEnhancedTableHead = ({
  headerGroupCells,
  headerCells,
  order,
  orderBy,
  onRequestSort,
  isTranslationRequired,
  handleCheckboxChange,
  /* whether the selectAll checkbox is visible and un/checked */
  selectAllCheckbox = { enabled: false, checked: false }
}) => {
  const classes = useCss(styles);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      {headerGroupCells && (
        <TableRow>
          {headerGroupCells.map((headerGroupCell, index) => (
            <>
              {!headerGroupCell?.hide && (
                <SbuxStyledTableCell
                  key={`th-${index}`}
                  align={"center"}
                  padding={headerGroupCell.disablePadding ? "none" : "normal"}
                  colSpan={headerGroupCell.colSpan}
                >
                  {isTranslationRequired ? (
                    <SbuxTranslate>{headerGroupCell.label}</SbuxTranslate>
                  ) : (
                    <>{headerGroupCell.label}</>
                  )}
                </SbuxStyledTableCell>
              )}
            </>
          ))}
        </TableRow>
      )}
      <TableRow>
        {headerCells &&
          headerCells.map((headerCell, index) => (
            <>
              {(!headerCell?.hide) && (
                <SbuxStyledTableCell
                  key={index}
                  align={headerCell.align}
                  padding={headerCell.disablePadding ? "none" : "normal"}
                  width={headerCell.minWidth}
                  sortDirection={orderBy === headerCell.id ? order : false}
                  className={headerCell.checkSelector ? classes.selectAllColumn : undefined }
                >
                  {headerCell.sortable ? (
                    <SbuxStyledTabelSortLabel
                      active={orderBy === headerCell.id}
                      direction={orderBy === headerCell.id ? order : "asc"}
                      onClick={createSortHandler(headerCell.id)}
                    >
                      {isTranslationRequired ? (
                        <SbuxTranslate>{headerCell.label}</SbuxTranslate>
                      ) : (
                        <>{headerCell.label}</>
                      )}
                      {orderBy === headerCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </SbuxStyledTabelSortLabel>
                  ) : isTranslationRequired ? (
                    headerCell.checkSelector && selectAllCheckbox.enabled ? (
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        disableFocusRipple
                        disableRipple
                        checked={selectAllCheckbox.checked}
                        onChange={handleCheckboxChange}
                        className={classes.cellCheckbox}
                      />
                    ) : (
                      <SbuxTranslate>{headerCell.label}</SbuxTranslate>
                    )
                  ) : (
                    <>{headerCell.label}</>
                  )}
                </SbuxStyledTableCell>
              )}
            </>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default SbuxEnhancedTableHead;
