const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",
    gridRowGap: theme.spacing(1.25),
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  section: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    "& > .MuiBox-root": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiCheckbox-root": {
      "& .MuiSvgIcon-root": { fontSize: 16 },
      color: `${theme.palette.primary.main} !important`,
      "&.Mui-checked": {
        color: `${theme.palette.primary.main} !important`,
      },
      "&.Mui-disabled": {
        opacity: 1,
        color: `${theme.palette.grey[400]} !important`,
      },
    },
    "& .checkboxStyle": {
      "& .MuiSvgIcon-root": { fontSize: 16 },
      color: `${theme.palette.primary.main} !important`,
      "&.Mui-checked": {
        color: `${theme.palette.primary.main} !important`,
      },
      "&.Mui-disabled": {
        opacity: 1,
        color: `${theme.palette.black[500]} !important`,
      },
    },
    "& .readOnlyCheckboxStyle": {
      "& .MuiSvgIcon-root": { fontSize: 16 },
      color: `${theme.palette.primary.main} !important`,
      "&.Mui-checked": {
        color: `${theme.palette.primary.main} !important`,
      },
      "&.Mui-disabled": {
        opacity: 1,
        color: `${theme.palette.grey[400]} !important`,
      },
    },
    "& .disabledLabel": {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      "&.Mui-disabled": {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
        cursor: "not-allowed !important",
      },
    },
  },
});

export const mainStyles = () => ({
  datagridContainer: {
    height: "69vh",
    width: "100%",
  },
});

export default styles;
