import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Autocomplete component
 * @param {object} props  MUI Autocomplete component props
 */
const SbuxGenericAutocomplete = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    typeof props.onOpen === "function" && props.onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    typeof props.onClose === "function" && props.onClose();
  };

  return (
    <Autocomplete
      sx={{
        "& input": {
          height: "2.0em",
          maxHeight: 32,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.slotProps?.input?.label}
          required={props.slotProps?.input?.required}
          helperText={props.slotProps?.input?.helperText}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {props.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
      {...props}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

export default SbuxGenericAutocomplete;
