import axios from "axios";
import { jwtToken } from "../utils/token";
import { logMessage } from "../../utils/amplifyLogger";

export const awsClientGateway = async ({
  endpoint,
  params = {},
  pathTemplate,
  method,
  queryParams = {},
  body = {},
}) => {
  try {
    const { headers } = await jwtToken();

    const config = {
      baseURL: endpoint ?? process.env.REACT_APP_BACKEND_API_ENDPOINT,
      url: pathTemplate,
      headers,
      params: { ...params, ...queryParams },
      method,
      data: typeof body === "object" ? { ...body } : body, // axios accepts string & sep.
      timeout: 0
    };

    const response = await axios(config);
    if (!response) {
      throw new Error(`Http error in ApiGateway Request! status: ${response}`);
    }
    return response;
  } catch (err) {
    logMessage(
      `Gateway`,
      `Something went wrong in ApiGateway Request : ${err}`,
      `error`
    );
  }
};
