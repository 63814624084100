import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";

import SbuxGenericAutocomplete from "../../../../components/SbuxAutoComplete/SbuxGenericAutocomplete";
import ConfirmActionModal from "./ConfirmActionModal";

import {
  retrieveApiData,
  resetApiData,
  postApiData,
} from "../../../../services/connect";
import {
  getConnectDatasetForApi,
  getConnectStatusSelector,
} from "../../../../selectors/connectSelector";
import {
  getArnSelector,
  getConnectedInstanceSelector,
} from "../../../../selectors/userInstanceSelector";

import { sortingComparator } from "../../../../utils/arrayUtil";
import { validateForm } from "../../../../utils/formValidation";
import {
  SNACK_BARS_SEVERITY_ERROR,
  SNACK_BARS_SEVERITY_SUCCESS,
} from "../../../../constants";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";

const API_NAME = {
  PHONE_NUMBER_LIST: "phone-number-list",
  INSTANCE_LIST: "instance-list",
  CONTACT_FLOW_LIST: "contact-flow-list",
  PORT_PHONE: "port-Phone",
};

const STATUS_REPORT = {
  ["post:pending"]: {
    message: "Operation in progress",
    severity: SNACK_BARS_SEVERITY_SUCCESS,
  },
  ["post:success"]: {
    message: "Phone Number ported successfully",
    severity: SNACK_BARS_SEVERITY_SUCCESS,
  },
  ["post:rejected"]: {
    message: "An error has ocurred while porting a Phone Number",
    severity: SNACK_BARS_SEVERITY_ERROR,
  },
  ["get:rejected"]: {
    message: "An error ocurred while loading data",
    severity: SNACK_BARS_SEVERITY_ERROR,
  },
};

/**
 * PortPhoneNumber component
 * @param {object} props  component props
 */
const PortPhoneNumber = (props) => {
  const classes = useCss(styles);
  const dispatch = useDispatch();
  const emptyDataset = {
    options: [],
    loading: false,
  };
  // State of datasets
  const [phoneNumberDataset, setPhoneNumberDataset] = useState({
    ...emptyDataset,
  });
  const [destinationInstanceDataset, setDestinationInstanceDataset] = useState({
    ...emptyDataset,
  });
  const [contactFlowDataset, setContactFlowDataset] = useState({
    ...emptyDataset,
  });

  // State of selections
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [instance, setInstance] = useState(null);
  const [contactFlow, setContactFlow] = useState(null);
  const [confirmAction, setConfirmAction] = useState(false);
  const [formValidity, setFormValidity] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Selectors
  const instanceArn = useSelector(getArnSelector);
  const connectedInstance = useSelector(getConnectedInstanceSelector);
  const phoneNumberList = useSelector(
    getConnectDatasetForApi(API_NAME.PHONE_NUMBER_LIST)
  );
  const instanceList = useSelector(
    getConnectDatasetForApi(API_NAME.INSTANCE_LIST)
  );
  const contactFlowList = useSelector(
    getConnectDatasetForApi(API_NAME.CONTACT_FLOW_LIST)
  );
  const processStatus = useSelector(getConnectStatusSelector);

  // Refs
  const lastProcessStatus = useRef(null);
  const form = useRef(null);

  const chainOfAction = {
    ["post:success"]: () => {
      resetForm(true);
    },
    ["get:rejected"]: () => {
      resetLoadingState();
    },
  };

  /**
   * Http GET request to retrieve a dataset
   * @param  {object}  queryParams               queryParams spec
   * @return {Promise}             a promise
   */
  const retrieveData = async (queryParams) => {
    const payload = {
      instanceId: connectedInstance.instanceId,
      queryParams,
    };

    await dispatch(retrieveApiData(payload));
  };

  /**
   * Http POST request to apply changes
   * @param  {object}  queryParams               queryParams spec
   * @param  {object}  body                      http request body
   * @return {Promise}             a promise
   */
  const postData = async (queryParams, body) => {
    const payload = {
      instanceId: connectedInstance.instanceId,
      id: "port-phone-number", // fake id to match apiGateway route
      queryParams,
      body,
    };
    await dispatch(postApiData(payload));
  };

  /**
   * Resets selected values from the form along with datasets
   * @param {Boolean} [onSuccess=false]  when true, phoneNumberDataset is reset as well
   */
  const resetForm = (onSuccess = false) => {
    setFormSubmitted(false);
    setPhoneNumber(null);
    setInstance(null);
    setContactFlow(null);
    onSuccess && setPhoneNumberDataset(emptyDataset);
    setContactFlowDataset(emptyDataset);
    setFormValidity({});
  };

  /**
   * Reset loading flag in the dataset state.
   *
   */
  const resetLoadingState = () => {
    if (phoneNumberDataset.loading) {
      setPhoneNumberDataset((prev) => {
        return { ...prev, loading: false };
      });
    }

    if (destinationInstanceDataset.loading) {
      setDestinationInstanceDataset((prev) => {
        return { ...prev, loading: false };
      });
    }

    if (contactFlowDataset.loading) {
      setContactFlowDataset((prev) => {
        return { ...prev, loading: false };
      });
    }
  };

  // Component effects
  useEffect(() => {
    // Clean datasets created by this component, only.
    return () => {
      dispatch(
        resetApiData({
          datasets: [
            API_NAME.PHONE_NUMBER_LIST,
            API_NAME.INSTANCE_LIST,
            API_NAME.CONTACT_FLOW_LIST,
          ],
        })
      );
    };
  }, []);

  // Report to snackbar based on Http status change
  useEffect(() => {
    if (lastProcessStatus.current !== processStatus) {
      lastProcessStatus.current = processStatus;
      if (typeof props.onProcessStatusChange === "function") {
        const report = STATUS_REPORT[processStatus];
        if (report) {
          props.onProcessStatusChange(report); // invoke event
        }
      }

      const followingAction = chainOfAction[processStatus];
      if (typeof followingAction === "function") {
        followingAction();
      }
    }
  }, [processStatus]);

  // Refresh PhoneNumber's dataset
  useEffect(() => {
    if (phoneNumberList?.length) {
      setPhoneNumberDataset({
        loading: false,
        options: phoneNumberList.slice(),
        isOptionEqualToValue: (option, value) =>
          option.PhoneNumberId === value.PhoneNumberId,
        getOptionLabel: (option) => option.PhoneNumber,
      });
    }
  }, [phoneNumberList]);

  // Refresh Instance's dataset
  useEffect(() => {
    if (instanceList?.length) {
      setDestinationInstanceDataset({
        loading: false,
        options: instanceList
          .slice()
          //.filter((record) => record.Id !== connectedInstance.instanceId) // exclude current instance
          .sort(sortingComparator("asc", "InstanceAlias")),
        isOptionEqualToValue: (option, value) => option.Id === value.Id,
        getOptionLabel: (option) => option.InstanceAlias,
      });
    }
  }, [instanceList]);

  // Refresh ContactFlow's dataset
  useEffect(() => {
    if (contactFlowList?.length) {
      setContactFlowDataset({
        loading: false,
        options: contactFlowList.slice().sort(sortingComparator("asc", "Name")),
        isOptionEqualToValue: (option, value) => option?.Id === value.Id,
        getOptionLabel: (option) => option?.Name,
      });
    }
  }, [contactFlowList]);

  // Form validation on change of required fields
  useEffect(() => {
    formSubmitted && updateFormValidity();
  }, [phoneNumber, instance]);

  /**
   * Validates form and updates its validity state
   * @return {Object} validation result
   */
  const updateFormValidity = () => {
    const validation = validateForm(form.current, ["phoneNumber", "instance"]);
    setFormValidity(validation);

    return validation;
  };

  /**
   * Lazy loading data set
   */
  const handleOpenPhoneNumberList = () => {
    if (!phoneNumberDataset.options.length) {
      const queryParams = {
        apiName: API_NAME.PHONE_NUMBER_LIST,
        instanceArn: encodeURIComponent(instanceArn),
      };
      retrieveData(queryParams);
      setPhoneNumberDataset((prev) => {
        return { ...prev, loading: true };
      });
    }
  };

  /**
   * Lazy loading data set
   */
  const handleOpenInstanceList = () => {
    if (!destinationInstanceDataset.options.length) {
      const queryParams = {
        apiName: API_NAME.INSTANCE_LIST,
      };
      retrieveData(queryParams);
      setDestinationInstanceDataset((prev) => {
        return { ...prev, loading: true };
      });
    }
  };

  /**
   * Lazy loading data set
   */
  const handleOpenContactFlowList = () => {
    if (!contactFlowDataset.options.length && instance) {
      const queryParams = {
        apiName: API_NAME.CONTACT_FLOW_LIST,
        instanceArn: encodeURIComponent(instance.Arn),
      };
      retrieveData(queryParams);
      setContactFlowDataset((prev) => {
        return { ...prev, loading: true };
      });
    }
  };

  const handleOnChangePhoneNumber = (event, newValue) => {
    setPhoneNumber(newValue);
  };

  const handleOnChangeDestinationInstance = (event, newValue) => {
    setInstance(newValue);
    setContactFlowDataset((prev) => {
      return { ...prev, options: [], loading: false };
    });
    setContactFlow(null);
  };

  /**
   * Clear form event handler
   */
  const handleClearForm = () => {
    resetForm();
  };

  /**
   * Opens a confirmation dialog before proceeding with porting a ph #
   */
  const handleOnClickPortPhone = () => {
    setFormSubmitted(true);
    const validation = updateFormValidity();

    if (!validation.invalid) {
      setConfirmAction(true);
    }
  };

  const handleOnCloseModal = () => {
    setConfirmAction(false);
  };

  /**
   * Handles action confirmation. Invokes api to port the ph #
   */
  const handleConfirmDialog = async () => {
    setConfirmAction(false);
    // prepare queryParams and http request body
    const queryParams = {
      apiName: API_NAME.PORT_PHONE,
    };
    const body = {
      phoneNumberId: phoneNumber.PhoneNumberId,
      instanceArn: instance.Arn,
      contactFlowId: contactFlow?.Id,
    };
    // invoke Api
    await postData(queryParams, body);
  };

  return (
    <Box className={classes.formContainer}>
      <Box
        component="form"
        className={classes.formControl}
        noValidate
        autoComplete="off"
        ref={form}
      >
        <Box>
          <SbuxGenericAutocomplete
            className="formControl"
            id="phoneNumber"
            required
            onOpen={handleOpenPhoneNumberList}
            isOptionEqualToValue={phoneNumberDataset.isOptionEqualToValue}
            getOptionLabel={phoneNumberDataset.getOptionLabel}
            loading={phoneNumberDataset.loading}
            options={phoneNumberDataset.options}
            onChange={handleOnChangePhoneNumber}
            slotProps={{
              input: {
                label: "Phone Number",
                required: true,
                helperText: formSubmitted ? formValidity["phoneNumber"] : null,
              },
            }}
            value={phoneNumber}
          />
        </Box>

        <Box>
          <SbuxGenericAutocomplete
            className="formControl"
            id="instance"
            onOpen={handleOpenInstanceList}
            isOptionEqualToValue={
              destinationInstanceDataset.isOptionEqualToValue
            }
            getOptionLabel={destinationInstanceDataset.getOptionLabel}
            loading={destinationInstanceDataset.loading}
            options={destinationInstanceDataset.options}
            onChange={handleOnChangeDestinationInstance}
            slotProps={{
              input: {
                label: "Destination Instance",
                required: true,
                helperText: formSubmitted ? formValidity["instance"] : null,
              },
            }}
            value={instance}
          />
        </Box>

        <Box>
          <SbuxGenericAutocomplete
            className="formControl"
            id="contactFlow"
            onOpen={handleOpenContactFlowList}
            isOptionEqualToValue={contactFlowDataset.isOptionEqualToValue}
            getOptionLabel={contactFlowDataset.getOptionLabel}
            loading={contactFlowDataset.loading}
            options={contactFlowDataset.options}
            onChange={(event, newValue) => {
              setContactFlow(newValue);
            }}
            slotProps={{
              input: {
                label: "Contact Flow",
              },
            }}
            value={contactFlow}
            noOptionsText={"Must select a Destination Instance first"}
          />
        </Box>
      </Box>

      <Box className={classes.actionContainer}>
        <Button
          type="submit"
          variant="contained"
          size="medium"
          onClick={handleOnClickPortPhone}
        >
          Port Number
        </Button>
        <Button variant="outlined" size="medium" onClick={handleClearForm}>
          Clear
        </Button>
      </Box>

      {confirmAction && (
        <ConfirmActionModal
          open={confirmAction}
          selections={{
            currentInstance: connectedInstance,
            phoneNumber,
            instance,
            contactFlow,
          }}
          handleOnClose={handleOnCloseModal}
          handleCancelDialog={handleOnCloseModal}
          handleConfirmDialog={handleConfirmDialog}
        />
      )}
    </Box>
  );
};

PortPhoneNumber.propTypes = {
  onProcessStatusChange: PropTypes.func,
};

export default PortPhoneNumber;
