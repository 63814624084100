import { useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import SbuxTextField from "../../../../../components/SubxTextField";
import MainHoursConfigTable from "../mainHours/MainHoursConfigTable";
import styles from "./styles";
import useCss from "../../../../../hooks/useCss";

const MainHoursEdit = ({ row, toggleDialogOpen, memoDataset, isManager }) => {
  const classes = useCss(styles);
  const initFormValues = {
    hoursCode: row.hoursCode,
    hoursName: row.hoursName,
    description: row.description,
    showForManagers: row.showForManagers || false,
    readOnly: row.readOnly || false,
  };

  const [formValue, setFormValue] = useState(initFormValues);
  const [hoursNameError, sethoursNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isFormDisabled = isManager && formValue.readOnly;
  useEffect(() => {
    memoDataset({ ...row });
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = { ...formValue, [name]: value };

    setFormValue(data);
    name === "hoursName" && sethoursNameError(value.length < 1 ? true : false);

    memoDataset({ [name]: value });
  };

  const handleCheckboxChange = (property) => (e) => {
    const { checked } = e.target;
    const updatedValues = { ...formValue, [property]: checked };

    if (property === "showForManagers" && !checked) {
      updatedValues.readOnly = false;
    }

    setFormValue(updatedValues);
    memoDataset(updatedValues);
  };
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={classes.formControl}
    >
      <Box>
        <SbuxTextField
          variant="outlined"
          label="#"
          disabled
          handleChange={handleChange}
          value={formValue.hoursCode}
          name="hoursCode"
        />
        <SbuxTextField
          variant="outlined"
          label="Hours Name"
          isError={hoursNameError}
          placeholder="Hours Name"
          handleChange={handleChange}
          value={formValue.hoursName}
          name="hoursName"
          errorMessage="Hours name required at least one character"
          error={hoursNameError}
          required
          disabled={isManager}
        />
        <SbuxTextField
          variant="outlined"
          placeholder="Description"
          label="Description"
          handleChange={handleChange}
          value={formValue.description}
          name="description"
          disabled={isManager}
        />
        <Box>
          <FormControlLabel
            className="disabledLabel"
            control={
              <Checkbox
                className="checkboxStyle"
                inputProps={{ "aria-label": "Manager Access" }}
                disableFocusRipple
                disableRipple
                checked={formValue.showForManagers}
                onChange={handleCheckboxChange("showForManagers")}
                disabled={isManager}
              />
            }
            label="Manager Access"
          />
        </Box>
        {formValue.showForManagers && (
          <Box>
            <FormControlLabel
              className="disabledLabel"
              control={
                <Checkbox
                  className="readOnlyCheckboxStyle"
                  inputProps={{ "aria-label": "Manager Read-only" }}
                  disableFocusRipple
                  disableRipple
                  checked={formValue.readOnly}
                  onChange={handleCheckboxChange("readOnly")}
                  disabled={isManager}
                />
              }
              label="Manager Read-only"
            />
          </Box>
        )}
      </Box>

      <Box className="fullWidth">
        <MainHoursConfigTable
          rowData={{ ...row }}
          toggleDialogOpen={toggleDialogOpen}
          memoDataset={memoDataset}
          isLoading={isLoading}
          isTableDisabled={isFormDisabled}
        />
      </Box>
    </Box>
  );
};

export default MainHoursEdit;
