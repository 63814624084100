import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Link } from "@mui/material";
import {
  GENESYS_RECORDING_FLAG,
  STOPCONTACTCALLBACK_RECORDING_FLAG,
} from "../../../../constants/index";
import { selectFeatureFlags } from "../../../../selectors/featureFlagSelector";

/* eslint-disable react-hooks/rules-of-hooks */
export const panelData = (
  connectedInstance,
  managerNavData,
  showOtherIndicator,
  handleOtherClick,
  handleItemClick,
  selectedIndicator
) => {
  const {
    appConfig: { managerNav: instanceManagerNav },
  } = connectedInstance;

  const featureFlags = useSelector(selectFeatureFlags);

  const [selectedExternalList, setSelectedExternalList] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [genesysRecordingEnabled, setGenesysRecordingEnabled] = useState(false);
  const [stopCallbackEnabled, setStopCallbackEnabled] = useState(false);

  useEffect(() => {
    if (!featureFlags || !featureFlags.length) return;

    const flagSetters = {
      [GENESYS_RECORDING_FLAG]: setGenesysRecordingEnabled,
      [STOPCONTACTCALLBACK_RECORDING_FLAG]: setStopCallbackEnabled,
    };

    Object.entries(flagSetters).forEach(([flag, setter]) => {
      const featureFlag = featureFlags.find((ff) => ff.label === flag);
      if (featureFlag) {
        setter(featureFlag.enabled);
      }
    });
  }, [featureFlags]);

  useEffect(() => {
    let result = [];
    Object.keys(managerNavData).map((m) => {
      result.push({
        name: m,
        isUrlClicked: false,
      });
    });
    setSelectedExternalList(result);
  }, []);
  const linkRenderer = (panelUrl, panelName) => {
    const updateSelectedExternalList = [...selectedExternalList];
    updateSelectedExternalList.forEach((item, index) => {
      if (item.name === panelName) {
        item.isUrlClicked = true;
        selectedExternalList[index] = item;
      } else {
        item.isUrlClicked = false;
        selectedExternalList[index] = item;
      }
    });
    const selectedPanelObject = updateSelectedExternalList.find(
      (f) => f.name === panelName
    );
    setSelectedExternalList(updateSelectedExternalList);
    selectedPanelObject && setSelectedPanel(selectedPanelObject);
    handleOtherClick();
    const newTab = window.open(panelUrl, "_blank", "noopener,noreferrer");
    newTab && newTab.focus();
  };

  const setNavigationUrl = (name) => {
    return (
      <Box sx={{ display: "flex" }}>
        <Link
          component="button"
          onClick={() => handleItemClick(name)}
          sx={{
            outline: "none !important",
            textDecoration: "none",
          }}
        >
          <span
            style={{
              marginBottom: 0.5,
              color:
                selectedIndicator && name && selectedIndicator[name]
                  ? "#000000"
                  : "#808080",
            }}
          >
            <SbuxTranslate>{name}</SbuxTranslate>
          </span>
        </Link>
        {selectedIndicator[name] && (
          <ChevronRightIcon sx={{ fontSize: 21, paddingTop: 0.2 }} />
        )}
      </Box>
    );
  };
  const getNavObject = (panel) => {
    const { name, url } = panel;

    if (
      (name === "Genesys Recordings" && !genesysRecordingEnabled) ||
      (name === "Callback Management" && !stopCallbackEnabled)
    ) {
      return null;
    }
    switch (name) {
      case "Feature Flags": {
        return setNavigationUrl(name);
      }
      case "Main Hours": {
        return setNavigationUrl(name);
      }
      case "Closure Messages": {
        return setNavigationUrl(name);
      }
      case "Data Management": {
        return setNavigationUrl(name);
      }
      case "Callback Management": {
        return setNavigationUrl(name);
      }
      case "Media Management": {
        return setNavigationUrl(name);
      }
      case "Temporary Messages": {
        return setNavigationUrl(name);
      }
      case "Genesys Recordings": {
        return setNavigationUrl(name);
      }
      case "Routing Profiles Delete": {
        return setNavigationUrl(name);
      }

      default: {
        return (
          <Box sx={{ display: "flex", width: 180 }}>
            <Link
              component="button"
              sx={{
                outline: "none !important",
                textDecoration: "none",
                textAlign: "initial",
              }}
              href={url}
              onClick={() => linkRenderer(url, name)}
            >
              <span
                style={{
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  color:
                    showOtherIndicator &&
                    selectedPanel &&
                    selectedPanel.name === name
                      ? "#000000"
                      : "#808080",
                }}
              >
                <SbuxTranslate>{name}</SbuxTranslate>
              </span>
              {url !== undefined && url !== "" && (
                <>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    sx={{
                      cursor: "pointer",
                      fontSize: 16,
                      width: "2vw",
                      marginTop: -0.5,
                      height: 18,
                      "&:focus": {
                        outline: 0,
                      },
                      "&.MuiIconButton-root": {
                        padding: 0,
                      },
                    }}
                  >
                    <LaunchIcon
                      sx={{
                        marginLeft:
                          showOtherIndicator &&
                          selectedPanel &&
                          selectedPanel.name === name
                            ? 1.5
                            : -1,
                        "@media only screen and (max-width: 1023px) and (min-width: 501px)":
                          {
                            marginLeft:
                              selectedPanel && selectedPanel.name === name
                                ? 3
                                : 0.3,
                          },
                        color:
                          showOtherIndicator &&
                          selectedPanel &&
                          selectedPanel.name === name
                            ? "#000000"
                            : "#808080",
                      }}
                    />
                    {showOtherIndicator &&
                      selectedPanel &&
                      selectedPanel.name === name && (
                        <ChevronRightIcon
                          sx={{
                            fontSize: 21,
                            paddingTop: 0.2,
                            marginLeft: -0.2,
                            color: "#000000",
                            "@media only screen and (max-width: 1023px) and (min-width: 501px)":
                              {
                                marginLeft:
                                  selectedPanel && selectedPanel.name === name
                                    ? -0.2
                                    : 1,
                              },
                          }}
                        />
                      )}
                  </IconButton>
                </>
              )}
            </Link>
          </Box>
        );
      }
    }
  };

  const getPanelsData = (panels) => {
    const result = panels.reduce((panelAcc, panelCur) => {
      const panelObj = Object.keys(managerNavData)?.find(
        (f) => f === panelCur.name
      );
      panelObj &&
        managerNavData[panelObj] &&
        panelAcc.push({
          ...panelCur,
          url: getNavObject(panelCur),
        });
      return panelAcc;
    }, []);
    return result;
  };

  const panelsInfo = instanceManagerNav.reduce((acc, cur) => {
    acc.push({
      ...cur,
      name: <SbuxTranslate>{cur.name}</SbuxTranslate>,
      panels: getPanelsData(cur.panels),
    });
    return acc;
  }, []);
  return panelsInfo;
};
/* eslint-enable react-hooks/rules-of-hooks */
