import { awsClientGateway } from "./common/gateway";
import { logMessage as logger } from "../utils/amplifyLogger";
import { clientLog as submitLog } from "./logging";

/**
 * Makes a httpRequest with supplied parameters
 * @param  {string}  pathname               API path to make the http request
 * @param  {string}  method                 HTTP request method
 * @param  {object}  body                   optional object
 * @param  {object}  queryParams            query string params
 * @return {Promise}          response
 */
export const httpRequest = async ({ pathname, method, queryParams, body }) => {
  const requestConfig = {
    pathTemplate: pathname,
    method,
    body,
    queryParams,
  };

  try {
    return await awsClientGateway(requestConfig);
  } catch (err) {
    const message = `Http ${method} invoke failed with error : ${err}`;
    // browser console logs
    logger(pathname, message, `error`);
    // submit logs to the server
    submitLog({
      pathname,
      method,
      message,
    });

    throw err;
  }
};
