import { useState, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import SbuxInputSearch from "../SbuxInputSearch";
import SbuxTableFilters from "./SbuxTableFilters";
import SbuxBasicTable from "./SbuxBasicTable";
import Box from "@mui/material/Box";
import { getComparator } from "./utils";
import { TABLE_DEFAULT_PAGE_SIZE } from "../../constants";
import useTextTranslate from "../../features/content/hooks/useTextTranslate";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { searchDataset } from "../../utils/tableUtil";


const SbuxEnhancedTable = ({
  rows = [],
  columns = [],
  currentPage = 0,
  filterColumns = null,
  filterTitle = null,
  groupColumns = null,
  defaultSortingField = "",
  defaultSortOrder = "desc",
  defaultRowsPerPage = null,
  defaultFilterColumns = [],
  setFilteredItem,
  RowSource,
  showAddNewButton = false,
  showCustomButton = false,
  showSubTabs = false,
  SubTabs = null,
  addNewButtonTitle = "",
  addCustomButtonTitle = "",
  showSearchBar = true,
  showFooter = true,
  showModalTable = false,
  showRefreshButton = false,
  isManager = false,
  isLoading = false,
  isdisableAddNewButton = false,
  isTranslationRequired = true,
  handleAddNewButtonClick = null,
  handleEditRowClick = null,
  handleDeleteRowClick = null,
  handleRefreshClick = null,
  handle24HoursClick = null,
  handleCustomButtonClick = null,
  updatedRowId = null,
  handleCheckboxChange,
  selectionStrategy = null,
  isCustomButtonDisabled = false,
  isTableDisabled = false,
  onRowsPerPageChange = null,
  onPageChange = null,
  /* Setting this handle will assume that sorting is implemented out of this component */
  onRequestSort = null,
  onDataFilterChange = null,
}) => {
  const classes = useCss(styles);
  const [rowData, setRowData] = useState([]);
  const [order, setOrder] = useState(defaultSortOrder);
  const [orderBy, setOrderBy] = useState(defaultSortingField);
  const [page, setPage] = useState(currentPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    defaultRowsPerPage === null ? TABLE_DEFAULT_PAGE_SIZE : defaultRowsPerPage
  );
  const [searchText, setSearchText] = useState("");

  const translatedSearchPlaceHolder = useTextTranslate("Search");
  useEffect(() => {
    setRowData(rows);
    searchText !== "" && handleInputChange(searchText);
  }, [rows]);

  useEffect(() => {
    const filterData =
      rows?.reduce((acc, cur) => {
        /* anti-pattern */
        const curValues = Object.values(cur);
        const isExists = curValues?.some((f) =>
          defaultFilterColumns?.includes(f)
        );
        if (isExists) {
          acc.push(cur);
        }
        return acc;
      }, []) ?? [];
    if (!!filterData?.length) {
      setRowData(filterData);
      // setSearchRowData(filterData);
    }
  }, [defaultFilterColumns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    typeof onPageChange === "function" && onPageChange(event, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    //setPage(0);
    handleChangePage(event, 0);
    typeof onRowsPerPageChange === "function" && onRowsPerPageChange(event);
  };

  /**
   * Updated internal state of sorting direction and field name. Also, invokes
   * props.onRequestSort function iif it is defined
   */
  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      const dir = isAsc ? "desc" : "asc";
      setOrder(dir);
      setOrderBy(property);

      typeof onRequestSort === "function" &&
        onRequestSort({ dir, field: property });
    },
    [orderBy, order, onRequestSort]
  );

  const handleInputChange = useCallback(
    (searchValue) => {
      setSearchText(searchValue);

      /* When onDataFilterChange filtering is expected to be handled by the parent component */
      if (typeof onDataFilterChange === "function") {
        onDataFilterChange({ searchText: searchValue });
        handleChangePage({}, 0);
      } else {
        const filterRows = searchDataset(columns, rows, searchValue);

        handleChangePage({}, 0);
        setRowData(filterRows);
      }
    },
    [rows, onDataFilterChange]
  );

  const BodySource = () => {
    let rowsSlice = rowData;

    if (rowsPerPage > 0) {
      if (!onRequestSort) {
        // Apply internal sort before paginate thru dataset
        rowsSlice = rowData.slice().sort(getComparator(order, orderBy));
      }
      rowsSlice = rowsSlice.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }

    return (
      rowsSlice &&
      rowsSlice.map((row, index) => {
        return (
          <RowSource
            key={index}
            row={row}
            columns={columns}
            handleEditRowClick={(row) => handleEditRowClick(row)}
            handleDeleteRowClick={(row) => handleDeleteRowClick(row)}
            handle24HoursClick={(row) => handle24HoursClick(row)}
            updatedRowId={updatedRowId}
          />
        );
      })
    );
  };

  return (
    <Box
      className={
        isTableDisabled ? classes.disabledContainer : classes.enabledContainer
      }
    >
      {showSearchBar && (
        <Box className={classes.searchBarContainer}>
          {showSubTabs && (
            <Box className="tabs">
              <SubTabs />
            </Box>
          )}
          <Box className={classes.actionContainer}>
            {showAddNewButton && (
              <Button
                variant="contained"
                size="medium"
                onClick={handleAddNewButtonClick}
                disabled={isTableDisabled || isdisableAddNewButton}
              >
                {addNewButtonTitle}
              </Button>
            )}
            {showRefreshButton && (
              <Button
                variant="contained"
                size="medium"
                onClick={handleRefreshClick}
                disabled={isTableDisabled}
              >
                {"Refresh"}
              </Button>
            )}
            {showCustomButton && (
              <Button
                variant="contained"
                size="small"
                onClick={handleCustomButtonClick}
              >
                {addCustomButtonTitle}
              </Button>
            )}
            {showCustomButton && (
              <Button
                variant="contained"
                size="small"
                onClick={handleCustomButtonClick}
                disabled={isTableDisabled || isCustomButtonDisabled}
              >
                {addCustomButtonTitle}
              </Button>
            )}
            <SbuxInputSearch
              value={searchText}
              translatedSearchPlaceHolder={
                isTranslationRequired ? translatedSearchPlaceHolder : "Search"
              }
              handleChange={(event) => handleInputChange(event.target.value)}
              handleClear={() => handleInputChange("")}
              sx={{ flex: 1, width: 150 }}
            />
            {filterColumns && (
              <SbuxTableFilters
                columns={filterColumns}
                title={filterTitle}
                defaultFilterColumns={defaultFilterColumns}
                setFilteredItem={setFilteredItem}
                display="flex"
                alignItems="center"
                sx={{ flexShrink: 0 }}
              />
            )}
          </Box>
        </Box>
      )}
      <SbuxBasicTable
        BodySource={BodySource}
        headerGroupCells={groupColumns}
        headerCells={columns}
        rowsPerPage={rowsPerPage}
        defaultRowsPerPage={defaultRowsPerPage}
        rows={rowData}
        page={page}
        order={order}
        orderBy={orderBy}
        showFooter={showFooter}
        selectionStrategy={selectionStrategy}
        showModalTable={showModalTable}
        onRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isManager={isManager}
        isLoading={isLoading}
        isTranslationRequired={isTranslationRequired}
        handleRefreshClick={handleRefreshClick}
        handleCustomButtonClick={handleCustomButtonClick}
        handleCheckboxChange={handleCheckboxChange}
      />
    </Box>
  );
};

export default SbuxEnhancedTable;
