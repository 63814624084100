import { useState, useEffect } from "react";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import MainHoursConfigTableRow from "../mainHours/MainHoursConfigTableRow";
import { detailColumns } from "./columns";

const MainHoursConfigTable = ({
  rowData,
  toggleDialogOpen,
  memoDataset,
  isLoading = false,
  isTableDisabled = false,
}) => {
  const [hoursConfig, setHoursConfig] = useState([]);

  const updateHoursConfigState = (hoursConfig) => {
    setHoursConfig(hoursConfig);
    memoDataset({ hoursConfig });
  };

  useEffect(() => {
    const result = handleRowDataUpdate(rowData.hoursConfig);
    updateHoursConfigState(result);
  }, [rowData.hoursConfig]);

  const handleRowDataUpdate = (hoursConfig) => {
    return hoursConfig && hoursConfig.length > 0
      ? hoursConfig.map((cur, index) => ({
          id: index,
          ...cur,
        }))
      : [];
  };

  const handleButtonClick = () => {
    if (isTableDisabled) return;

    const rows = [...hoursConfig];
    const rowCount = rows.length;
    const initializeHoursConfig = {
      id: rowCount,
      day: "sunday",
      start: "09:00",
      end: "17:00",
      description: "",
      isOpenFor24Hours: false,
    };

    rows.push(initializeHoursConfig);
    updateHoursConfigState(rows);
  };

  const handleDeleteRowClick = (row) => {
    if (isTableDisabled) return;

    const filteredRows = hoursConfig.filter((f) => f.id !== row.id);
    updateHoursConfigState(filteredRows);
  };

  /**
   * Handle the row edit event and updates the row found in the array
   * @param  {object} row               updated row
   */
  const handleEditRowClick = (row) => {
    if (isTableDisabled) return;

    const rows = hoursConfig.slice();
    const rowIndex = rows.findIndex((r) => r.id === row.id);
    if (rowIndex !== -1) {
      rows[rowIndex] = { ...row };
      updateHoursConfigState(rows);
    }
  };

  return (
    <SbuxEnhancedTable
      rows={hoursConfig}
      columns={detailColumns}
      defaultSortingField={""}
      defaultSortOrder={"asc"}
      RowSource={MainHoursConfigTableRow}
      showFooter={false}
      showSearchBar
      showAddNewButton
      addNewButtonTitle={`Add new`}
      handleAddNewButtonClick={handleButtonClick}
      showModalTable
      isManager
      isTranslationRequired={false}
      handleDeleteRowClick={handleDeleteRowClick}
      handleEditRowClick={handleEditRowClick}
      isLoading={isLoading}
      isTableDisabled={isTableDisabled}
    />
  );
};

export default MainHoursConfigTable;
