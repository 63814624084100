import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SbuxGenericGrid,
  SbuxGridToolbar,
} from "../../../../../components/SbuxDataGrid/SbuxGenericGrid";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import SbuxDialog2 from "../../../../../components/SbuxDialog2";

import { Box, Button, DialogContent, DialogActions } from "@mui/material";
import MainHoursAdd from "./MainHoursAdd";
import MainHoursEdit from "./MainHoursEdit";
import { masterColumns } from "../mainHours/columns";

import { createOrUpdateMainHours } from "../../../../../services/mainHours";
import { isEmptyString } from "../../../../../utils/stringUtil";

import { getTimezoneSelector } from "../../../../../selectors/settingsSelector";
import { dataNormalizer } from "./util";

import { mainStyles } from "./styles";
import useCss from "../../../../../hooks/useCss";

const MainHoursMasterTable = ({
  mainHoursData,
  handleRefrehClick,
  isManager,
}) => {
  const classes = useCss(mainStyles);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();
  const [dataset, setDataset] = useState({});

  const timezone = useSelector(getTimezoneSelector);

  const toggleDialogOpen = () => {
    setOpen((p) => !p);
    setEditRow(false);
  };

  const handleOnRowClick = ({ row }) => {
    setRowToEdit(row);
    setEditRow(true);
    setOpen(true);
  };

  const handleMemoDataset = (data) => {
    const newDataset = {
      ...dataset,
      ...data,
    };
    setDataset(newDataset);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const dialogConfig = {
    title: "Details",
  };

  const handleSave = async () => {
    if (!!dataset) {
      const mainHoursItem = { ...dataset };

      if (isEmptyString(mainHoursItem.hoursName)) {
        return;
      }

      let hoursConfigWithoutIds = [...mainHoursItem.hoursConfig];
      hoursConfigWithoutIds = hoursConfigWithoutIds.reduce((acc, cur) => {
        acc.push({
          start: cur.start,
          end: cur.end,
          description: cur.description,
          day: cur.day,
          isOpenFor24Hours: cur.isOpenFor24Hours,
        });
        return acc;
      }, []);
      const readOnlyValue = isManager
        ? mainHoursItem.readOnly
        : mainHoursItem.readOnly || false;

      const data = {
        hoursCode: mainHoursItem.hoursCode,
        hoursName: mainHoursItem.hoursName,
        description: mainHoursItem.description,
        hoursConfig: [...hoursConfigWithoutIds],
        showForManagers: isManager || mainHoursItem.showForManagers || false,
        readOnly: readOnlyValue,
      };
      toggleDialogOpen();

      await dispatch(createOrUpdateMainHours(data));
      handleRefrehClick(); // Refresh data after save
    }
  };

  const isFormValid = () => {
    if (!!dataset) {
      const { hoursName } = dataset;
      if (isManager && dataset.readOnly) {
        return true;
      }
      return isEmptyString(hoursName);
    }
    return false;
  };

  /**
   * Toolbar component to spc the rendering of the Datagrid toolbar
   */
  const DataGridToolbar = () => {
    return (
      <SbuxGridToolbar>
        <Button variant="contained" size="medium" onClick={toggleDialogOpen}>
          {<SbuxTranslate>{"Add new hours"}</SbuxTranslate>}
        </Button>
      </SbuxGridToolbar>
    );
  };

  return (
    <>
      <Box>
        <SbuxGenericGrid
          rows={dataNormalizer(mainHoursData, timezone)}
          columns={masterColumns}
          loading={isLoading}
          slots={{
            toolbar: DataGridToolbar,
          }}
          slotClasses={{
            datagrid: {
              container: classes.datagridContainer,
            },
          }}
          onRowClick={handleOnRowClick}
          clickableRow
        />
      </Box>

      {open && (
        <SbuxDialog2
          open={open}
          config={dialogConfig}
          handleOnClose={toggleDialogOpen}
        >
          <DialogContent dividers>
            {!editRow ? (
              <MainHoursAdd
                toggleDialogOpen={toggleDialogOpen}
                memoDataset={handleMemoDataset}
              />
            ) : (
              <MainHoursEdit
                row={rowToEdit}
                toggleDialogOpen={toggleDialogOpen}
                memoDataset={handleMemoDataset}
                isManager={isManager}
              />
            )}
          </DialogContent>

          <DialogActions>
            <Box className="actionContainer">
              <Button
                variant="contained"
                size="medium"
                disabled={isFormValid()}
                onClick={handleSave}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                size="medium"
                onClick={toggleDialogOpen}
              >
                Close
              </Button>
            </Box>
          </DialogActions>
        </SbuxDialog2>
      )}
    </>
  );
};

export default MainHoursMasterTable;
