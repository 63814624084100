import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const getSettingsData = async (instanceId, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      pathTemplate: `/userConfig/${instanceId}`,
      method: "GET",
    });

    return response;
  } catch (error) {
    clientLog({
      component: `settings`,
      message: `Something went wrong in getSettingsData API => ${error}`,
    });
    logMessage(
      `settings API`,
      `Something went wrong in getSettingsData API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getSettingsData api endpoint");
  }
};

export const getSettings = createAsyncThunk(
  "settings/getSettings",
  async (args, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    return await getSettingsData(instanceId, rejectWithValue);
  }
);

export const createOrUpdateSettings = createAsyncThunk(
  "settings/createOrUpdateSettings",
  async (settings, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;

      await awsClientGateway({
        pathTemplate: `/userConfig/${instanceId}`,
        method: "POST",
        body: { ...settings },
      });
      return await getSettingsData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `settings`,
        message: `Something went wrong in createOrUpdateSettings API => ${error}`,
      });
      logMessage(
        `settings`,
        `Something went wrong in createOrUpdateSettings API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in createOrUpdateSettings api endpoint");
    }
  }
);

export const createOrUpdateLocaleSettings = createAsyncThunk(
  "settings/createOrUpdateLocaleSettings",
  async (translationsSource, { getState, rejectWithValue }) => {
    const isResetToDefalut = translationsSource.languageCode ? true : false;
    const sourceText = isResetToDefalut
      ? translationsSource.default
      : translationsSource;
    const bodyData = {
      translations: [...sourceText],
    };
    try {
      const { locale } = await getState().settings;
      const languageCode = isResetToDefalut
        ? translationsSource.languageCode
        : locale;
      let response;
      if (!languageCode || languageCode === "en") {
        response = {
          data: {
            translations: {
              ...sourceText,
            },
            languageCode: "en",
          },
        };
      } else {
        response = await awsClientGateway({
          pathTemplate: `/translate/${locale}`,
          method: "POST",
          body: { ...bodyData },
        });
      }
      return response;
    } catch (error) {
      clientLog({
        component: `settings`,
        message: `Something went wrong in createOrUpdateLocaleSettings API => ${error}`,
      });
      logMessage(
        `settings`,
        `Something went wrong in createOrUpdateLocaleSettings API => ${error}`,
        `error`
      );
      return rejectWithValue(
        "Error in createOrUpdateLocaleSettings api endpoint"
      );
    }
  }
);

export const getReOrderedTablist = createAction("settings/getReOrderedTablist");

export const getLocale = createAction("settings/getLocale");

export const getCCPLocaleStatus = createAction("settings/getCCPLocaleStatus");

export const getSelectedTimezone = createAction("settings/getSelectedTimezone");
export const getUpdatedTimezone = createAction("settings/getUpdatedTimezone");
