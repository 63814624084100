export const columns = [
  {
    label: " ",
    id: "sno",
    minWidth: "3px",
    checkSelector: true,
  },

  {
    label: "Initiation timestamp",
    id: "initiationTimestamp",
    sortable: true,
    minWidth: "1000px",
  },
  {
    label: "Status",
    id: "eventType",
    sortable: true,
    minWidth: "200px",
  },
  {
    label: "Initial ContactId",
    id: "initialContactId",
    sortable: false,
    minWidth: "1000px",
  },
  {
    label: "Contact Id",
    id: "contactId",
    sortable: false,
    minWidth: "1000px",
  },
  {
    label: "Queue Name",
    id: "queueName",
    sortable: true,
    minWidth: "1000px",
  },
];
