import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import {
  CURRENT_TICKET_OR_CASE_NUMBER,
  CURRENT_VAR_TICKET_OR_CASE_NUMBER,
} from "../constants";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const getTicketOrCaseNumberDefault = createAction(
  "current/getTicketOrCaseNumberDefault"
);

export const getTicketOrCaseNumber = createAction(
  "current/getTicketOrCaseNumber"
);

export const updateCurrentCtr = createAsyncThunk(
  "current/updateCurrentCtr",
  async ({ instanceId, contactId, ticketOrCaseNumberValue }) => {
    try {
      const response = await awsClientGateway({
        pathTemplate: `/connectApis/${instanceId}/${contactId}?apiName=contact-attributes`,
        method: "POST",
        body: {
          [CURRENT_TICKET_OR_CASE_NUMBER]: ticketOrCaseNumberValue,
          [CURRENT_VAR_TICKET_OR_CASE_NUMBER]: ticketOrCaseNumberValue,
        },
      });
      if (!response) {
        throw new Error("updateCurrentCtr API reponse error");
      }
      return response;
    } catch (error) {
      clientLog({
        component: `current`,
        message: `Something went wrong in updateCurrentCtr API => ${error}`,
      });
      logMessage(
        `current`,
        `Something went wrong in updateCurrentCtr API => ${error}`,
        `error`
      );
    }
  }
);

export const saveContactToHistoryAction = createAction(
  "current/saveContactToHistory"
);

export const setTicketValidation = createAction("current/ticketValidation");

export const resetState = createAction("current/resetState");
