import moment from "moment";

export const DEFAULT_US_DATE_FORMAT = "YYYY-MM-DD hh:mm A";

export const today = (dateFormat) => {
  return moment().format(dateFormat);
};

export const formatMillisToMinutesAndSeconds = (ms) => {
  return moment.utc(ms).format("mm:ss");
};

export const dateWithFormat = (date, format) => {
  return moment(new Date(date)).format(format);
};

export const dateWithUSDateFormat = (date) => {
  return dateWithFormat(date, DEFAULT_US_DATE_FORMAT);
};

export const utcDateWithFormat = (date, format) => {
  return moment.utc(date).format(format);
};

export const utcDateWithDefaultUSDateFormat = (date) => {
  return utcDateWithFormat(date, DEFAULT_US_DATE_FORMAT);
};

export const dateAtTimezone = (
  date,
  timezone,
  format = DEFAULT_US_DATE_FORMAT
) => {
  return moment.utc(date, null).tz(timezone).format(format);
};

export const isDateValid = (date) => {
  return moment(date).isValid();
};
