import React from "react";
import { StyledGridOverlay } from "./styled";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { ReactComponent as SbuxLogo } from "../../assets/image.svg";

export const CustomNoRowsOverlay = React.memo(({ children }) => {
  const classes = useCss(styles);

  return (
    <StyledGridOverlay>
      <SbuxLogo className={classes.sbuxLogo} />

      <div className={classes.overlayContent}>
        {children}
      </div>
    </StyledGridOverlay>
  );
});
