import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

/**
 * Confirmation dialog
 * @param {object} props  component props
 */
const ConfirmActionModal = (props) => {
  const dialogConfig = {
    title: <SbuxTranslate>{`Action summary`}</SbuxTranslate>,
    hasBackdrop: false,
    asModal: true,
    disableBackdropClose: false,
  };

  /**
   * Creates dialog content summary
   * @return {Object}                 Component
   */
  const createSummary = ({
    currentInstance,
    phoneNumber,
    instance,
    contactFlow,
  }) => {
    return (
      <Box>
        <Typography variant="h5" gutterBottom>
          Phone number <strong>{`${phoneNumber.PhoneNumber}`}</strong> from
          instance <strong>{`${currentInstance.instanceAlias}`}</strong> will be
          ported to{" "}
          {contactFlow && (
            <>
              contact flow <strong>{`${contactFlow.Name}`}</strong> which is
              associated to{" "}
            </>
          )}
          instance <strong>{`${instance.InstanceAlias}`}</strong>
        </Typography>

        <Typography variant="h5">Confirm change?</Typography>
      </Box>
    );
  };

  return (
    <SbuxDialog2
      open={props.open}
      config={dialogConfig}
      handleOnClose={props.handleOnClose}
    >
      <DialogContent dividers>{createSummary(props.selections)}</DialogContent>

      <DialogActions>
        <Box className="actionContainer">
          <Button
            variant="contained"
            size="medium"
            onClick={props.handleConfirmDialog}
          >
            <SbuxTranslate>{"Confirm"}</SbuxTranslate>
          </Button>
          <Button
            variant="outlined"
            size="medium"
            onClick={props.handleCancelDialog}
          >
            <SbuxTranslate>{"Cancel"}</SbuxTranslate>
          </Button>
        </Box>
      </DialogActions>
    </SbuxDialog2>
  );
};

export default ConfirmActionModal;
