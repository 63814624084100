import moment from "moment";
import {
  CONTACT_ATTRIBUTES_ORDER_TEMPLATE,
  DATAGRID_DEFAULT_PAGE_SIZE,
  DATAGRID_ROWS_PER_PAGE,
} from "../../constants";

export const predicateBy = (field) => {
  return (a, b) => {
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    }
    return 0;
  };
};

export const getTemplateData = (name, contactAttributeOrdering) => {
  // Get the template
  const template = CONTACT_ATTRIBUTES_ORDER_TEMPLATE.find(
    (template) => template.name === name
  );
  // If there is a specific ordering found, use it to override the default
  if (contactAttributeOrdering && template) {
    if (contactAttributeOrdering[template.name]) {
      template.id = contactAttributeOrdering[template.name];
    }
  }
  return template;
};

export const getRowsPerPage = (defaultRowsPerPage) => {
  if (defaultRowsPerPage) {
    return [defaultRowsPerPage].concat(DATAGRID_ROWS_PER_PAGE);
  } else {
    return [DATAGRID_DEFAULT_PAGE_SIZE].concat(DATAGRID_ROWS_PER_PAGE);
  }
};

export const audioToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const blobToBase64 = async (blob) => {
  const audioBlob = await fetch(blob).then((r) => r.blob());
  const audioFile = new File([audioBlob], "record.wav", { type: "audio/wav" });
  return audioToBase64(audioFile);
};

export const getLastUpdated = (row, timezone) => {
  let userName,
    dateTime = null;
  const lastUpdatedBy = row?.lastUpdatedBy ? row["lastUpdatedBy"] : null;
  const lastUpdated = row?.lastUpdated
    ? moment.utc(row["lastUpdated"], "YYYY-MM-DD hh:mm A")
    : null;
    userName = lastUpdatedBy;
    if (userName && userName.includes("@")) {
      userName = userName.split("@")[0];
    } else if (!userName) {
      userName = "system";
    }
  dateTime =
    lastUpdated && moment(lastUpdated).isValid()
      ? moment(lastUpdated, null).tz(timezone).format("YYYY-MM-DD hh:mm A")
      : "No date found";
  return `${dateTime} ${userName}`;
};

export const getDataManagementDateTime = (value, timezone) => {
  const utcValue = moment.utc(value, "YYYY-MM-DD hh:mm A");
  return utcValue && moment(utcValue).isValid()
    ? moment(utcValue, null).tz(timezone).format("YYYY-MM-DD hh:mm A")
    : "No date found";
};

export const getHistoryUpdatedTimezone = (row, timezone) => {
  let lastUpdatedTime = null;
  lastUpdatedTime = row?.timestamp
    ? moment.utc(row["timestamp"], "YYYY-MM-DD hh:mm A")
    : null;
  return lastUpdatedTime && moment(lastUpdatedTime).isValid()
    ? moment(lastUpdatedTime, null).tz(timezone).format("YYYY-MM-DD hh:mm A")
    : "no date found";
};

const getMediaFileObject = (row, data) => {
  const result = data.reduce((acc, cur) => {
    const languages =
      Object.keys(cur).length > 0 &&
      Object.keys(cur).filter((a) => a.includes("message"));

    const mediaFileObj =
      cur[languages.find((a) => a.split("_")[1] === row.languageCode)];

    const isExistsInAcc = acc?.find(
      (f) => f.id === cur.id || f.sequenceNumber == cur.sequenceNumber
    );
    if (
      mediaFileObj &&
      mediaFileObj.value === row.filePath &&
      cur.status !== "Expired" &&
      !isExistsInAcc
    ) {
      acc.push({
        ...cur,
      });
    }
    return acc;
  }, []);
  return result;
};

export const getMediaMgtToolTipMsg = (
  row,
  closureMsgData,
  temporaryMsgData
) => {
  let result = "";
  const colsureMsgMediaIdInUse = getMediaFileObject(row, closureMsgData);
  const TemporaryMsgMediaIdInUse = getMediaFileObject(row, temporaryMsgData);
  const mediaFileInUse = [
    ...colsureMsgMediaIdInUse,
    ...TemporaryMsgMediaIdInUse,
  ];
  if (mediaFileInUse && mediaFileInUse.length > 0) {
    result = `The media file you were trying delete already in use.
    ${
      colsureMsgMediaIdInUse && colsureMsgMediaIdInUse.length > 0
        ? "Closure Messages Active/Scheduled Count: " +
          colsureMsgMediaIdInUse.length
        : ""
    }
    ${
      TemporaryMsgMediaIdInUse && TemporaryMsgMediaIdInUse.length > 0
        ? "Temporary Messages Active/Scheduled Count: " +
          TemporaryMsgMediaIdInUse.length
        : ""
    } `;
  }
  return result;
};

export const changeLastUpdatedDate = (data, currentTimezone) => {
  return data?.reduce((acc, cur) => {
    if (cur.lastUpdated) {
      acc.push({
        ...cur,
        lastUpdated: moment(cur.lastUpdated, null)
          .tz(currentTimezone)
          .format("YYYY-MM-DD hh:mm A"),
      });
    }
    return acc;
  }, []);
};
