import { logMessage } from "./amplifyLogger";
import { clientLog } from "../services/logging";

const MAX_LENGTH = process.env.REACT_APP_COLUMN_LENGTH_BEFORE_ELLIPSIS ?? 25;

export const ellipse = (value) => {
  try {
    if (value && MAX_LENGTH && value.length > MAX_LENGTH) {
      value = `${value.substring(0, MAX_LENGTH - 3)}...`;
    }
  } catch (err) {
    clientLog({
      component: `stringUtil`,
      message: `Unable to ellipse [${value}]. Error: ${err}`,
    });
    logMessage(
      "stringUtil",
      `Unable to ellipse [${value}]. Error: ${err}`,
      "error"
    );
  }
  return value;
};

export const getTitleForEllipse = (value) => {
  if (value && value.length > MAX_LENGTH) {
    return value;
  } else {
    return "";
  }
};

export const isEmptyString = (value) => {
  return (typeof value === 'string' && value.trim() === "");
}

export const isNotEmptyString = (value) => {
  return (typeof value === 'string' && value.trim().length > 0);
}

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
;
