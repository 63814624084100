import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import {
  getStopContactData,
  deleteStopContact,
} from "../../../../../services/stopContact";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { EditStopContactsRow } from "./EditStopContactRow";
import { columns } from "./columns";
import SbuxLoader from "../../../../../components/SbuxLoader";
import {
  getStopContactDataSelector,
  getStopContactStatusSelector,
} from "../../../../../selectors/stopContactSelector";
import { logMessage } from "../../../../../utils/amplifyLogger";
import { getConnectedInstanceSelector } from "../../../../../selectors/userInstanceSelector";
import { subOnProcessedContactEvent } from "../../../../../graphql/api";
import { OPERATION, TABLE_DEFAULT_PAGE_SIZE } from "../../../../../constants";
import {
  insertContactEvent,
  updateContactEvent,
  deleteContactEvent,
  updateCheckStatus,
  updateCheckStatusForRowsInPage,
  sortDataset,
  unCheckAll,
} from "../../../../../slices/stopContactSlice";
import { mapContactEvent } from "./util";
import { useDebounce } from "../../../../../utils/commonUtil";
import { isNotEmptyString } from "../../../../../utils/stringUtil";
import {
  searchDataset,
  RowSelectionStrategy,
} from "../../../../../utils/tableUtil";

const paginationConfig = {
  rowsPerPage: TABLE_DEFAULT_PAGE_SIZE,
  page: 0,
};

const expectedInitialMethod = "CALLBACK";

const StopContactTable = () => {
  const dispatch = useDispatch();
  const [dataset, setDataset] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pagination, setPagination] = useState(paginationConfig);

  const [selectionStrategy, setSelectionStrategy] = useState({
    strategy: RowSelectionStrategy.BY_PAGE,
    mapping: new Map([[0, false]]),
  });
  const [searchText, setSearchText] = useState();

  const connectedInstance = useSelector(getConnectedInstanceSelector);
  const stopContactData = useSelector(getStopContactDataSelector);
  const loadingStatus = useSelector(getStopContactStatusSelector);
  const subs = useRef([]);

  /**
   * Fetch data from backend
   */
  const fetchStopContactData = async () => {
    await dispatch(getStopContactData());
  };

  /**
   * Reset row selection strategy
   * @param {number} newStrategy  optional new strategy
   */
  const resetSelectionStrategy = (newStrategy, keepMapping = false) => {
    setSelectionStrategy({
      strategy: newStrategy ?? selectionStrategy.strategy,
      mapping: keepMapping ? selectionStrategy.mapping : new Map(),
    });
  };

  /**
   * Data initialization and event subscription
   */
  const initialize = () => {
    // Clean up prev subscriptions
    subs.current.forEach((sub) => sub.unsubscribe());
    subs.current = [];

    // fetch data
    fetchStopContactData();
    // reset internal state
    setDataset([]);
    setSelectedItems([]);
    setSearchText(null);
    resetSelectionStrategy();
    setPagination(paginationConfig);

    // init  subscription
    setTimeout(() => {
      const onProcessSub = subOnProcessedContactEvent(
        connectedInstance.instanceId
      ).subscribe(handleSubscription, (err) =>
        logMessage(
          `Stop Contact `,
          `Something went wrong in Subscription API => ${err}`,
          `error`
        )
      );

      subs.current = [onProcessSub];
    }, 0);
  };

  /**
   * On instance selection and first load
   */
  useEffect(() => {
    initialize();

    return () => {
      // IMPORTANT: Clean up prev subscriptions
      subs.current.forEach((sub) => sub.unsubscribe());
    };
  }, [connectedInstance.instanceId]);

  /**
   * On data / filter text change
   */
  useEffect(() => {
    const contactEvents = stopContactData.slice();

    if (isNotEmptyString(searchText)) {
      const filtered = searchDataset(columns, contactEvents, searchText);
      setDataset(filtered);
    } else {
      setDataset(contactEvents);
    }

    // Disable selectAll when there is no data to select
    if (!contactEvents.length) {
      resetSelectionStrategy(RowSelectionStrategy.DISABLED);
    } else {
      resetSelectionStrategy(RowSelectionStrategy.BY_PAGE, true);
    }

    const selected = stopContactData.filter((item) => item.isChecked);
    setSelectedItems(selected);
  }, [stopContactData, searchText]);

  /**
   * Handle event subscription
   * @param  {Object} data               contactEvent data
   */
  const handleSubscription = (data) => {
    const contactEvent = data?.data?.onProcessedContactEvent;

    if (
      // safe check, it might not ever be true
      !contactEvent ||
      contactEvent.initiationMethod !== expectedInitialMethod
    ) {
      return;
    }

    const operation = contactEvent.__operation;
    switch (operation) {
      case OPERATION.CREATE:
        dispatch(insertContactEvent(contactEvent));
        break;
      case OPERATION.UPDATE: {
        dispatch(updateContactEvent(contactEvent));
        updateSelectionList(contactEvent);
        break;
      }
      case OPERATION.DELETE: {
        dispatch(deleteContactEvent(contactEvent));
        updateSelectionList(contactEvent);
        break;
      }
    }
  };

  /**
   * Find and remove item from selection list
   * @param  {string} initialContactId               id to match item in list
   */
  const updateSelectionList = ({ initialContactId }) => {
    const filtered = selectedItems.filter(
      (item) => item.initialContactId !== initialContactId
    );
    if (filtered.length !== selectedItems.length) {
      setSelectedItems(filtered);
    }
  };

  /**
   * Handle checkbox change event
   * @param  {Event} event               DOM event
   */
  const handleSelectAllChange = async (event) => {
    const checked = event.target.checked;

    // All checked by page mapping
    const mapping = new Map(selectionStrategy.mapping.entries());
    mapping.set(pagination.page, checked);
    setSelectionStrategy({
      strategy: selectionStrategy.strategy,
      mapping,
    });

    await dispatch(
      updateCheckStatusForRowsInPage({
        pagination,
        isChecked: checked,
      })
    );
  };

  /**
   * Handle onClick event of the Delete button
   */
  const handleDelete = async () => {
    const payload = selectedItems.map((item) => mapContactEvent(item));
    await dispatch(deleteStopContact(payload));

    // Clear up selection
    setSelectedItems([]);
    resetSelectionStrategy();
    setPagination({ ...pagination, page: 0 });
  };

  /**
   * Handle checkbox event at the row level
   * @param  {Object} row               data
   */
  const handleEditRowClick = (row) => {
    // update check status to the record in the store
    dispatch(updateCheckStatus(row));
  };

  /**
   * Handle onRowsPerPageChange event
   * @param  {Event} event               DOM event
   */
  const handleRowsPerPageChange = async (event) => {
    setPagination({ ...pagination, rowsPerPage: event.target.value });
    resetSelectionStrategy();
  };

  /**
   * Handle onPageChange event
   * @param  {Event} event                    DOM event
   * @param  {number} pageNumber              page number
   */
  const handlePageChange = (event, pageNumber) => {
    setPagination({ ...pagination, page: pageNumber });
  };

  /**
   * Clear all selected items
   */
  const clearSelection = async () => {
    setSelectedItems([]);
    resetSelectionStrategy();
    await dispatch(unCheckAll());
  };

  /**
   * Handle onRequestSort event
   * @param  {Object} sorting               sorting spec
   */
  const handleRequestSort = (sorting) => {
    resetSelectionStrategy();
    dispatch(sortDataset(sorting));
  };

  /**
   * Handle onRefreshButtonClick event.
   * Re-init data along with the state of the component
   */
  const handleOnRefreshButtonClick = () => {
    initialize();
  };

  /**
   * Handle onDataFilterChange event
   */
  const handleDataFilterChange = useDebounce((event) => {
    if (searchText !== event.searchText) {
      setSearchText(event.searchText);

      if (selectedItems.length) {
        clearSelection();
      }

      if (isNotEmptyString(event.searchText)) {
        resetSelectionStrategy(RowSelectionStrategy.DISABLED);
      } else {
        resetSelectionStrategy(RowSelectionStrategy.BY_PAGE);
      }

      if (pagination.page > 0) {
        setPagination({ ...pagination, page: 0 });
      }
    }
  }, 400);

  /**
   * Create a label with the total number of selected values
   * @param  {Array} selection               selected values
   * @return {string}           label
   */
  const labelCountSelection = (selection) => {
    return Array.isArray(selection) && selection.length > 0
      ? ` (${selection.length})`
      : "";
  };

  if (loadingStatus === "pending") {
    return <SbuxLoader />;
  }

  return (
    <SbuxEnhancedTable
      rows={dataset}
      columns={columns}
      currentPage={pagination.page}
      defaultSortingField={"initiationTimestamp"}
      defaultSortOrder={"asc"}
      RowSource={EditStopContactsRow}
      showFooter={true}
      showSearchBar={true}
      showRefreshButton
      isManager={true}
      selectionStrategy={selectionStrategy}
      showAddNewButton
      handleAddNewButtonClick={handleDelete}
      isdisableAddNewButton={dataset.length === 0 || selectedItems.length === 0}
      addNewButtonTitle={
        <SbuxTranslate>{`Delete${labelCountSelection(
          selectedItems
        )}`}</SbuxTranslate>
      }
      handleEditRowClick={handleEditRowClick}
      handleCheckboxChange={handleSelectAllChange}
      handleRefreshClick={handleOnRefreshButtonClick}
      onRowsPerPageChange={handleRowsPerPageChange}
      onPageChange={handlePageChange}
      onRequestSort={handleRequestSort} /* handle sorting  */
      onDataFilterChange={handleDataFilterChange}
    />
  );
};

export default StopContactTable;
