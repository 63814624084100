import Checkbox from "@mui/material/Checkbox";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import styles from "../../styles";
import { columns } from "./columns";
import { COLUMN_ID_SNO } from "../../../../../constants/index";
import { dateWithFormat } from "../../../../../utils/dateUtil";
import useCss from "../../../../../hooks/useCss";
import { calcRowClassName, isInvalidRow } from "./util";

export const EditStopContactsRow = ({ row, handleEditRowClick }) => {
  const classes = useCss(styles);

  const handleCheckboxChange = (event, record) => {
    handleEditRowClick({ ...record, isChecked: event.target.checked });
  };

  return (
    <SbuxStyledTableRow
      className={calcRowClassName(row, classes.invalid)}
      sx={{ "& > *": { borderBottom: "unset" } }}
    >
      {columns.map((column) => {
        if (column.id === COLUMN_ID_SNO) {
          return (
            <SbuxStyledTableCell key={column.id} sx={{ padding:"6px 0px 6px 6px !important"}}>
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                disableFocusRipple
                disableRipple
                checked={row?.isChecked}
                onChange={(e) => { handleCheckboxChange(e, row) }}
                className={classes.cellCheckbox}
                disabled={isInvalidRow(row)}
              />
            </SbuxStyledTableCell>
          );
        } else {
          return (
            <SbuxStyledTableCell
              key={column.id}
              className={classes.stopContactCell}
            >
              {column.id === "initiationTimestamp"
                ? dateWithFormat(row[column.id], "YYYY-MM-DD hh:mm A")
                : row[column.id]}
            </SbuxStyledTableCell>
          );
        }
      })}
    </SbuxStyledTableRow>
  );
};
