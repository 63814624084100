const styles = (theme) => ({
  radioFlex: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  checkboxList: {
    marginTop: 5,
    "& > .MuiFormGroup-root > .MuiFormControlLabel-root": {
      marginTop: 10,
      marginLeft: 2,
      "& .MuiFormControlLabel-label.Mui-disabled": {
        color: `${theme.palette.black[500]} !important`,
      },
    },
  },
  rowMultiCtrl: {
    display: "flex",
    width: 390,
    alignItems: "center",
    justifyContent: "space-evently",
    "& > .MuiBox-root": {
      marginRight: 6,
    },
    "& > label": {
      fontSize: 12,
    },
    "& > .MuiBox-root input[type=number]": {
      fontSize: 14,
    },
  },
});

export default styles;
