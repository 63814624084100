/**
 * Removes custome props not supported by DataGrid component.
 * Some props are interpreted differently. By keeping them the custom behavior will
 * be overwritten.
 * @param  {Object} props               Datagrid props
 * @return {Object}       subset of Datagrid props
 */
export const normalizeProps = (props) => {
  const normalProps = { ...props };

  delete normalProps["showQuickFilter"];
  delete normalProps["slotClasses"];
  delete normalProps["clickableRow"];

  return normalProps;
};

/**
 * Creates a map with the classes to assign to each row
 * @param  {Object} props               Datagrid props
 * @return {Map}       Map of css classes
 */
export const getRowClasses = (props) => {
  if (props.clickableRow) {
    return new Map([
      [0, "even clickable"],
      [1, "odd clickable"],
    ]);
  }

  return new Map([
    [0, "even"],
    [1, "odd"],
  ]);
};

/**
 * Calculates the best getRowId function for the dataset
 * @param  {Object} record               a record from a dataset
 * @return {function}        getRowId function
 */
export const calcRowIdFn = (record) => {
  let fn = (row) => row["__rowId"];
  if (record) {
    if (record["id"]) {
      fn = (row) => row["id"];
    }
  }

  return fn;
};
