/**
 * Default comparator to sort array data
 * @param  {string} a               a value
 * @param  {string} b               b value
 * @return {number}   1 if a < b, -1 if b > a, otherwise 0
 */
const localeComparator = (a, b) => {
  return a.localeCompare(b);
};

/**
 * Returns comparator with the calculated order of the sort based on dir parameter
 * @param  {string} dir                   asc | desc
 * @param  {string} orderBy               field name
 * @return {function}         comparator
 */
export const sortingComparator = (dir, orderBy) => {
  const order = dir === "desc" ? -1 : 1;

  return (a, b) =>
    order * localeComparator(a[orderBy].toString(), b[orderBy].toString());
};

/**
 * Find an index in an array where a new value can be inserted preserving the order of
 * the items in the array
 * Pre: array is in ascending/descending order
 * Complexity: O(log N)
 * @param  {Array} dataset               a sorted array
 * @param  {string} value                a value to evaluate
 * @param  {Object} sorting              sorting spec as { dir, field }
 *                                       each item of the array is an Object
 * @return {number}         an existing index of the array, otherwise the leftmost index
 */
export const binarySearchInsertionIndex = (dataset, value, { dir, field }) => {
  let left = 0;
  let right = dataset.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (dataset[mid][field] === value) {
      break;
    }

    if (dir === "asc") {
      if (dataset[mid][field] < value) {
        left = mid + 1;
      } else if (dataset[mid][field] > value) {
        right = mid - 1;
      }
    } else {
      if (dataset[mid][field] < value) {
        right = mid - 1;
      } else if (dataset[mid][field] > value) {
        left = mid + 1;
      }
    }
  }

  return left;
};
