import {
  DATAGRID_DEFAULT_PAGE_SIZE,
  DATAGRID_ROWS_PER_PAGE,
} from "../constants";

import {
  utcDateWithDefaultUSDateFormat,
  dateAtTimezone,
  isDateValid,
} from "./dateUtil";

/**
 * Escape values to support in a RegExp
 * @param  {string} value               the value
 * @return {string}       escaped value
 */
export const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

/**
 * Search on dataset using fields displayed in the UI only
 * @param {Array} columns     list of columns
 * @param {Array} dataset     dataset to filter
 * @param {string} searchText  text to match
 * @return {Array}            a filtered version of the dataset
 */
export const searchDataset = (columns, dataset, searchText) => {
  const fieldsOnUi = new Set(columns.map((item) => item.id));
  const searchRegex = new RegExp(escapeRegExp(searchText), "i");

  const filtered = dataset.filter((row) =>
    Object.keys(row).some(
      (field) =>
        fieldsOnUi.has(field) &&
        row[field] &&
        searchRegex.test(row[field].toString())
    )
  );

  return filtered;
};

/**
 * When presenting a select all checkbox in a column, what strategy should be
 * use to select rows
 * @type {Enum}
 */
export const RowSelectionStrategy = {
  DISABLED: 0,
  ALL_ROWS: 1,
  BY_PAGE: 2,
};

/* interface definition */
export const selectionStrategyDefault = {
  strategy: RowSelectionStrategy.DISABLED,
  mapping: undefined,
  checked: undefined,
};

/**
 * Whether the selectionStrategy is diff than DISABLED
 * @param  {Object}  selectionStrategy               selection strategy
 * @return {Boolean}                   true when strategy is diff than DISABLED
 */
export const isSelectionStrategyEnabled = (selectionStrategy) => {
  const resolving = selectionStrategy ?? selectionStrategyDefault;

  return resolving.strategy !== RowSelectionStrategy.DISABLED;
};

/**
 * Returns the checked value based on selectionStrategy
 * @param  {Object} selectionStrategy               selection strategy
 * @param  {number} currentPage                     page number
 * @return {boolean}                   is checked value
 */
export const selectionStrategyResolver = (selectionStrategy, currentPage) => {
  const resolving = selectionStrategy ?? selectionStrategyDefault;

  if (resolving.strategy === RowSelectionStrategy.ALL_ROWS) {
    return resolving.checked ?? false;
  } else if (resolving.strategy === RowSelectionStrategy.BY_PAGE) {
    return resolving.mapping?.get(currentPage) ?? false;
  }

  return false;
};

/**
 * Calculate the list of page sizes to support by a MuiDataGrid
 * @param  {number} defaultRowsPerPage               default rows per page value
 * @return {Array}                    list of page sizes
 */
export const calcPageSizeOptions = (defaultRowsPerPage) => {
  if (defaultRowsPerPage) {
    return [defaultRowsPerPage].concat(DATAGRID_ROWS_PER_PAGE);
  } else {
    return [DATAGRID_DEFAULT_PAGE_SIZE].concat(DATAGRID_ROWS_PER_PAGE);
  }
};

/**
 * Create a rowId to each record iif needed
 * @param  {Array}              dataset
 * @return {Array}              altered dataset
 */
export const mapRowIdentifier = (dataset = []) => {
  return typeof dataset[0] === "object" && !dataset[0]["id"]
    ? dataset.map((record, idx) => {
        return {
          ...record,
          __rowId: idx,
        };
      })
    : dataset;
};

export const formatLastUpdated = (row, timezone) => {
  let userName,
    dateTime = null;
  const lastUpdatedBy = row.lastUpdatedBy ?? "system";
  const lastUpdated = row.lastUpdated
    ? utcDateWithDefaultUSDateFormat(row.lastUpdated)
    : null;

  userName = lastUpdatedBy;
  if (userName && userName.includes("@")) {
    userName = userName.split("@")[0];
  }

  dateTime =
    lastUpdated && isDateValid(lastUpdated)
      ? dateAtTimezone(lastUpdated, timezone)
      : "No date found";

  return `${dateTime} ${userName}`;
};

export const formatDateAtTimezoneOrDefault = (
  value,
  timezone,
  defaultValue
) => {
  const utcValue = utcDateWithDefaultUSDateFormat(value);

  if (utcValue && isDateValid(utcValue))
    return dateAtTimezone(utcValue, timezone);

  return defaultValue;
};
