import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";
//Action for selected instanceinfo from linkedlist
export const getConnectedInstance = createAction(
  "instance/getConnectedInstance"
);

export const getUserInstance = createAsyncThunk(
  "/instanceInfo",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await awsClientGateway({
        pathTemplate: "/instanceInfo",
        method: "POST",
      });
      return response;
    } catch (error) {
      clientLog({
        component: `instance`,
        message: `Something went wrong in getUserInstance API => ${error}`,
      });
      logMessage(
        `instance`,
        `Something went wrong in getUserInstance API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getUserInstance api endpoint");
    }
  }
);
