const operators = [
  [
    // { value: "eq", name: "Equal" },
    // { value: "ne", name: "Not Equal" },
    { key: "gt", value: "gt", name: "Greater than" },
    { key: "gte", value: "gte", name: "Greater than or equal" },
    { key: "lt", value: "lt", name: "Less than" },
    { key: "lte", value: "lte", name: "Less than or equal" },
  ],
  [{ key: "con", value: "con", name: "Contains" }],
];

/**
 * Value transformation to comply with the backend spec or the frontend
 * readability.
 */
export const transformFilterValueSpec = (type, value, readable = false) => {
  if (type === "timeDuration") {
    const { hh = 0, mm = 0, ss = 0 } = value;
    const calc = hh * 3600 + mm * 60 + ss;
    const trFn = (calc, hh, mm, ss) => {
      if (calc <= 0) {
        return `${calc} seconds.`;
      }
      const text = [];
      hh > 0 && text.push(`${hh} hour(s)`);
      mm > 0 && text.push(`${mm} minute(s)`);
      ss > 0 && text.push(`${ss} second(s)`);

      return text.join(", ");
    };
    // ms / s
    return !readable ? 1000 * Math.abs(calc) : trFn(calc, hh, mm, ss);
  }

  return value;
};

/**
 * Build filter payload part to comply with the backend spec
 */
export const buildFilterSpec = (formControls) => {
  const filterSpec = [];
  for (const key in formControls) {
    const { name, value, op, type } = formControls[key];
    filterSpec.push({
      field: name,
      op,
      value: transformFilterValueSpec(type, value),
    });
  }

  return filterSpec;
};

/**
 * Operators for given type
 * @param  {string} type               type to evaluate
 * @return {Array}      list of operators
 */
export const operatorsFor = (type) => {
  if (type === "text") {
    return operators[1];
  }

  return operators[0];
};

export const normalizeFormControls = (formControls, configItems) => {
  const filterList = [];
  const requiredFormControls = {};

  configItems.forEach((item) => {
    // Required fields are displayed on the form all the time
    if (!!item.required) {
      if (!formControls[item.name]) {
        const operators = operatorsFor(item.type);
        const defaultOp = operators[0].value;
        requiredFormControls[item.name] = {
          type: item.type,
          label: item.label,
          name: item.name,
          value: "",
          op: defaultOp,
          required: true,
        };
      }
    } else {
      // Not required fields
      filterList.push({
        key: item.name,
        name: item.label,
        value: item,
      });
    }
  });

  return {
    filterList,
    requiredFormControls
  }
}

/**
 * Creates a map of operators where key is the operator.value
 * @return {Map} map of operators by value
 */
export const flatmapOperators = () => {
  const flatMap = {};
  for (const ops of operators) {
    for (const op of ops) {
      flatMap[op.value] = op.name.toUpperCase();
    }
  }

  return flatMap;
}
