import { dateWithUSDateFormat } from "../../../../../../utils/dateUtil";

/**
 * Data transformation function
 * @param  {Array} data               dataset
 * @return {Array}      transformed dataset
 */
export const transformDataFn = (data) => {
  return (
    data?.map((record, index) => {
      return {
        ...record,
        id: index,
        videoURL: record.videoURL,
        audioURL: record.audioURL,
        startTime: dateWithUSDateFormat(record.startTime),
        endTime: dateWithUSDateFormat(record.endTime),
      };
    }) || []
  );
};

/**
 * Calculate the total number of rows at the moment. Since pagination behavior depends on the
 * number of rows, an offset value is included in the total number of rows to let know the
 * component that we haven't reached the end of the dataset and there is more to fetch.
 * @param  {number}  dataLength                size of the last dataset
 * @param  {number}  pageSize                  size of the page
 * @param  {number}  page                      current page number
 * @param  {Boolean} hasNextPage               whether there is a next page to fetch
 * @return {number}              total number of rows
 */
export const calcTotal = (dataLength, pageSize, page, hasNextPage) => {
  // Calc to update the grid to whether enable "Next" button

  const offSet = hasNextPage ? 1 : 0;

  let total = 0;
  if (dataLength === pageSize) {
    total = pageSize * (page + 1);
  } else {
    total = pageSize * page + dataLength;
  }

  return offSet * pageSize + total;
};
