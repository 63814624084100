import { EventType } from "../../../../../constants";

/**
 * Makiung sure data is sent to the backend with the expected attributes
 * @param  {Object} data               contact event object
 * @return {object}      mapped object
 */
export const mapContactEvent = (data) => {
  const contactEvent = {
    contactId: data.contactId,
    queueName: data.queueName,
    initialContactId: data.initialContactId,
    queueArn: data.queueArn,
    initiationTimestamp: data.initiationTimestamp,
    instanceId: data.instanceId,
    eventType: data.eventType,
    initiationMethod: data.initiationMethod,
    ttlExpiryTime: data.ttlExpiryTime,
    channel: data.channel,
    previousContactId: data.previousContactId,
  };

  if (data.status) {
    contactEvent.status = data.status;
  }

  return contactEvent;
};

/**
 * Whether the given row contains a status/state that is considered invalid
 * @param  {Object}  row               a Row
 * @return {Boolean}     true/false based on the case
 */
export const isInvalidRow = (row) => {
  return (
    row.eventType !== EventType.INITIATED ||
    row.status === EventType.STOPPED ||
    row.status === EventType.SUBMITTED
  );
};

/**
 * Returns the given className if the row is considered invalid
 * @param  {Object} row                     a Row
 * @param  {string} className               className
 * @return {string|undefined}           className if the row is valid, undefined otherwise.
 */
export const calcRowClassName = (row, className) => {
  return isInvalidRow(row) ? className : undefined;
};
