import SbuxCard from "../../../../../components/SbuxCard";
import React, { useEffect, useState, useMemo } from "react";
import GenesysRecordingsDataGrid from "./components/GenesysRecordingsDataGrid";
import { useSelector, useDispatch } from "react-redux";
import {
  getGenesysRecordingsAudio,
  getGenesysRecordingsVideo,
} from "../../../../../services/genesysRecordings";
import { getDataGridColumns } from "./columns";
import { filterList } from "./filters";
import {
  getVideoMediaContentSelector,
  getAudioMediaContentSelector,
} from "../../../../../selectors/genesysRecordingsSelector";

const GenesysRecordingsTabs = () => {
  const dispatch = useDispatch();
  const videoMediaContent = useSelector(getVideoMediaContentSelector);
  const audioMediaContent = useSelector(getAudioMediaContentSelector);

  const handleAudioDowload = async (value) => {
    await dispatch(getGenesysRecordingsAudio(value));
  };

  const handleVideoDowload = async (value) => {
    await dispatch(getGenesysRecordingsVideo(value));
  };

  const [columns] = useState(
    getDataGridColumns(handleAudioDowload, handleVideoDowload)
  );

  const dataGridConfig = useMemo(
    () => ({
      filterList,
      columns,
    }),
    [filterList, columns]
  );

  useEffect(() => {
    if (videoMediaContent) {
      window.open(videoMediaContent.mediaContent, "_blank");
    }
  }, [videoMediaContent]);

  useEffect(() => {
    if (audioMediaContent) {
      window.open(audioMediaContent.mediaContent, "_blank");
    }
  }, [audioMediaContent]);

  return (
    <SbuxCard className="insideTabContainer">
      <GenesysRecordingsDataGrid
        columns={dataGridConfig.columns}
        filterList={dataGridConfig.filterList}
      />
    </SbuxCard>
  );
};

export default React.memo(GenesysRecordingsTabs);
