/**
 * Debounce function to excute a callback when the time comes
 * @param  {Function} callback               a callback function
 * @param  {number}   wait                   time to wait in milliseconds
 * @return {Function}            closure containing a future execution of the callback function
 */
export const useDebounce = (callback, wait) => {
  let timeout = null;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), wait);
  };
};
