import { createAsyncThunk, createAction } from "@reduxjs/toolkit";

import { httpRequest } from "./httpRequest";

/**
 * HTTP GET request
 */
const retrieveData = async (payload, rejectWithValue) => {
  const { instanceId, queryParams } = payload;

  try {
    const requestConfig = {
      pathname: `/connectApis/${instanceId}`,
      method: "GET",
      queryParams,
    };
    return await httpRequest(requestConfig);
  } catch (err) {
    return rejectWithValue(`Error invoking connectApis. ${err}`);
  }
};

export const resetApiData = createAction("connect/reset");

export const retrieveApiData = createAsyncThunk(
  "connect/retrieve",
  async (payload, { rejectWithValue }) => {
    return await retrieveData(payload, rejectWithValue);
  }
);

/**
 * HTTP DELETE request
 */
export const deleteApiData = createAsyncThunk(
  "connect/delete",
  async (payload, { rejectWithValue }) => {
    const { instanceId, id, queryParams } = payload;
    const requestConfig = {
      pathname: `/connectApis/${instanceId}/${id}`,
      method: "DELETE",
      queryParams,
    };

    try {
      await httpRequest(requestConfig);

      return await retrieveData(payload, rejectWithValue);
    } catch (err) {
      return rejectWithValue(`Error invoking connectApis. ${err}`);
    }
  }
);

/**
 * Http POST request
 */
export const postApiData = createAsyncThunk(
  "connect/post",
  async (payload, { rejectWithValue }) => {
    const { instanceId, id, queryParams, body } = payload;
    const requestConfig = {
      pathname: `/connectApis/${instanceId}/${id}`,
      method: "POST",
      queryParams,
      body,
    };

    try {
      return await httpRequest(requestConfig);
    } catch (err) {
      return rejectWithValue(`Error invoking connectApis. ${err}`);
    }
  }
);
