import { createSlice } from "@reduxjs/toolkit";
import { getStopContactData, deleteStopContact } from "../services/stopContact";
import { EventType } from "../constants";
import {
  sortingComparator,
  binarySearchInsertionIndex,
} from "../utils/arrayUtil";

const initialState = {
  status: "idle",
  isLoading: false,
  stopContactData: [],
  sorting: {
    dir: "asc",
    field: "initiationTimestamp",
  },
  isSCDelete: false,
  deletionInProgress: false,
  isSCPending: false,
  isSCError: false,
};

/**
 * Mark status of all checked items as submitted
 * @param  {Array} items               array of objects
 * @param  {Object} state               current state
 */
const markRecordsAsSubmitted = (items, state) => {
  // O(m)
  const submittedIds = new Set(items.map(item => item.initialContactId));
  // O(n)
  state.stopContactData.forEach((item) => {
    if (submittedIds.has(item.initialContactId) && item.isChecked) {
      item.isChecked = false;
      item.status = EventType.SUBMITTED;
    }
  });
};

const stopContactSlice = createSlice({
  name: "stopContact",
  initialState,
  reducers: {
    insertContactEvent: (state, { payload }) => {
      const item = {
        ...payload,
        isChecked: false,
      };

      if (Array.isArray(state.stopContactData)) {
        const { field } = state.sorting;
        const index = binarySearchInsertionIndex(
          state.stopContactData,
          item[field],
          state.sorting
        );

        if (index >= 0) {
          // Insert at index
          state.stopContactData.splice(index, 0, item);
        } else {
          // Add to tail of the list
          state.stopContactData.push(item);
        }
      } else {
        // Start a brand new list
        state.stopContactData = [item];
      }
    },

    updateContactEvent: (state, { payload }) => {
      const { initialContactId } = payload;
      const item = {
        ...payload,
        isChecked: false,
      };

      const foundIndex = state.stopContactData.findIndex(
        (elm) => elm.initialContactId === initialContactId
      );
      if (foundIndex >= 0) {
        state.stopContactData[foundIndex] = item;
      } else {
        // Insert updated item to the store
        state.stopContactData.push(item);
      }
    },

    deleteContactEvent: (state, { payload }) => {
      const { initialContactId } = payload;

      state.stopContactData = state.stopContactData.filter(
        (elm) => elm.initialContactId !== initialContactId
      );
    },

    updateCheckStatus: (state, { payload }) => {
      const { initialContactId, isChecked } = payload;

      const foundIndex = state.stopContactData.findIndex(
        (elm) => elm.initialContactId === initialContactId
      );
      if (foundIndex >= 0) {
        state.stopContactData[foundIndex] = {
          ...state.stopContactData[foundIndex],
          isChecked,
        };
      }
    },

    updateCheckStatusForRowsInPage: (state, { payload }) => {
      const {
        pagination: { rowsPerPage, page },
        isChecked,
      } = payload;

      // prevent re-render when no data to select
      if (page * rowsPerPage < state.stopContactData.length) {
        // [0....(k = page * rowsPerPage), k+1, k+2...k + rowsPerPage... len]
        const data = state.stopContactData.slice();

        for (
          let k = page * rowsPerPage;
          k < data.length && k < page * rowsPerPage + rowsPerPage;
          k++
        ) {
          if (data[k].eventType === EventType.INITIATED) {
            data[k].isChecked = isChecked;
          }
        }

        state.stopContactData = data;
      }
    },

    sortDataset: (state, { payload }) => {
      const { dir, field } = payload;
      const data = state.stopContactData.slice();

      data.sort(sortingComparator(dir, field));

      state.stopContactData = data;
      state.sorting = { dir, field };
    },

    unCheckAll: (state) => {
      for (let k = 0; k < state.stopContactData.length; k++) {
        state.stopContactData[k].isChecked = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStopContactData.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
        state.stopContactData = [];
        state.isSCPending = true;
        state.isSCDelete = false;
        state.isSCError = false;
      })
      .addCase(getStopContactData.fulfilled, (state, { payload }) => {
        // Sort data before presenting it
        const { dir, field } = state.sorting;
        const data = payload.data.slice();
        data.sort(sortingComparator(dir, field));

        state.status = "success";
        state.isLoading = false;
        state.stopContactData = data;
        state.isSCPending = false;
        state.isSCError = false;
        state.isSCDelete = false;
      })
      .addCase(getStopContactData.rejected, (state) => {
        state.status = "rejected";
        state.isLoading = false;
        state.stopContactData = [];
        state.isSCPending = false;
        state.isSCError = true;
        state.isSCDelete = false;
      })
      .addCase(deleteStopContact.pending, (state) => {
        state.isSCDelete = false;
        state.isSCPending = true;
        state.isSCError = false;
        state.deletionInProgress = true;
      })
      .addCase(deleteStopContact.fulfilled, (state, action) => {
        const {
          meta: { arg: items },
        } = action;

        state.isSCPending = false;
        state.isSCDelete = true;
        state.deletionInProgress = false;

        markRecordsAsSubmitted(items, state);
      })
      .addCase(deleteStopContact.rejected, (state) => {
        state.isSCDelete = false;
        state.isSCPending = false;
        state.isSCError = true;
        state.deletionInProgress = false;
      });
  },
});

export const {
  insertContactEvent,
  updateContactEvent,
  deleteContactEvent,
  updateCheckStatus,
  updateCheckStatusForRowsInPage,
  sortDataset,
  unCheckAll,
} = stopContactSlice.actions;
export default stopContactSlice.reducer;
