const styles = (theme) => ({
  root: {
    height: "calc(74vh)",
  },
  dataGridRoot: {
    "& .MuiDataGrid-iconSeparator": {
      // visibility: "hidden",
    },
    display: "flex",
    border: "0 !important",
    paddingTop: "5px",
  },
  sbuxLogo: {
    height: 120,
    width: 120,
    maxWidth: 120,
    maxHeight: 120,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "auto",
    },
  },
  overlayContent: {
    top: 10,
    fontSize: 20,
    fontWeight: 600,
  },
  gridToolbar: {
    justifyContent: "flex-end",
    alignItems: "center",

    "& > .MuiButton-root": {
      width: "auto",
      whiteSpace: "nowrap",
    },

    "& > *:not(button)": {
      flex: "1 1 auto !important",
    },

    "& > .MuiFormControl-root": {
      flexDirection: "row",
      alignItems: "inherit",
      "& > .MuiFormLabel-root": {
        display: "flex",
        alignItems: "center",
        whiteSpace: "break-spaces",
        lineHeight: "1.23em",
        paddingLeft: theme.spacing(1),
      },
      "& > .MuiFormControl-fullWidth": {
        [theme.breakpoints.up("lg")]: {
          maxWidth: "40%",
        },
        [theme.breakpoints.down("md")]: {
          width: "auto",
        },
      },
    },
  },
});

export default styles;
