import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

//User Story: CCPS:3722 Added a Common function for getCallHistory and AddCallHistory to avoid page refresh
const getHistroyData = async (rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      pathTemplate: "/callHistory",
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `history`,
      message: `Something went wrong in getCallHistory API => ${error}`,
    });
    logMessage(
      `history`,
      `Something went wrong in getCallHistory API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getCallHistory api endpoint");
  }
};

export const getCallHistory = createAsyncThunk(
  "history/getCallHistory",
  async (args, { rejectWithValue }) => await getHistroyData(rejectWithValue)
);

export const addCallHistory = createAsyncThunk(
  "history/addCallHistory",
  async (currentSaveAttributes, { rejectWithValue }) => {
    try {
      await awsClientGateway({
        pathTemplate: "/callHistory",
        method: "POST",
        body: { ...currentSaveAttributes },
      });

      return await getHistroyData(rejectWithValue);
    } catch (error) {
      clientLog({
        component: `history`,
        message: `Something went wrong in addCallHistory API => ${error}`,
      });
      logMessage(
        `history`,
        `Something went wrong in addCallHistory API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in addCallHistory api endpoint");
    }
  }
);

export const updateCallHistory = createAsyncThunk(
  "history/updateCallHistory",
  async (updateHistoryData, { rejectWithValue }) => {
    try {
      const response = await awsClientGateway({
        pathTemplate: "/callHistory",
        method: "PUT",
        body: { ...updateHistoryData },
      });
      const result = {
        ...updateHistoryData,
      };
      return result;
    } catch (error) {
      clientLog({
        component: `history`,
        message: `Something went wrong in updateCallHistory API => ${error}`,
      });
      logMessage(
        `history`,
        `Something went wrong in updateCallHistory API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in updateCallHistory api endpoint");
    }
  }
);

export const setHistoryDialNumberValidateStatus = createAction(
  "history/setHistoryDialNumberValidateStatus"
);

export const getTranscriptDataByContactId = createAsyncThunk(
  "history/getTranscripts",
  async (
    { contactId, bucketName, areTranscriptsEnabledForInstance, timestamp },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        userInstance: { connectedInstance: { instanceId = {} } = {} },
      } = getState();
      const response = await awsClientGateway({
        pathTemplate: `/callHistory?contactId=${contactId}&instanceId=${instanceId}&areTranscriptsEnabledForInstance=${areTranscriptsEnabledForInstance}&bucketName=${bucketName}`,
        method: "GET",
      });
      if (!response?.data) {
        return rejectWithValue("Error in getTranscripts api endpoint");
      }
      return response?.data.find(
        (history) =>
          history?.["ContactID"] === contactId &&
          history?.timestamp === timestamp
      );
    } catch (error) {
      clientLog({
        component: `history`,
        message: `Something went wrong in getTranscriptsAPI API => ${error}`,
      });
      logMessage(
        `history`,
        `Something went wrong in getTranscripts API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getTranscripts api endpoint");
    }
  }
);
