import { useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import SbuxTextField from "../../../../../components/SubxTextField";
import MainHoursConfigTable from "../mainHours/MainHoursConfigTable";
import styles from "./styles";
import useCss from "../../../../../hooks/useCss";

const MainHoursAdd = ({ toggleDialogOpen, memoDataset, isManager }) => {
  const classes = useCss(styles);

  const initFormValues = {
    hoursName: "",
    description: "",
    hoursConfig: [],
    showForManagers: false,
    readOnly: false,
  };

  const [formValue, setFormValue] = useState(initFormValues);
  const [hoursNameError, sethoursNameError] = useState(false);

  useEffect(() => {
    memoDataset(initFormValues);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = { ...formValue, [name]: value };

    setFormValue(data);
    name === "hoursName" && sethoursNameError(value.length < 1 ? true : false);

    memoDataset({ [name]: value });
  };

  const handleCheckboxChange = (property) => (e) => {
    const { checked } = e.target;
    const updatedValues = { ...formValue, [property]: checked };

    if (property === "showForManagers" && !checked) {
      updatedValues.readOnly = false;
    }

    setFormValue(updatedValues);
    memoDataset(updatedValues);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={classes.formControl}
    >
      <SbuxTextField
        isError={hoursNameError}
        error={hoursNameError}
        label={"Hours Name"}
        handleChange={handleChange}
        value={formValue.hoursName}
        name={"hoursName"}
        required
        errorMessage={
          hoursNameError ? "Hours name required at least one character" : " "
        }
      />
      <SbuxTextField
        label={"Description"}
        handleChange={handleChange}
        value={formValue.description}
        name={"description"}
      />

      <Box>
        <FormControlLabel
          className="disabledLabel"
          control={
            <Checkbox
              className="checkboxStyle"
              inputProps={{ "aria-label": "Manager Access" }}
              disableFocusRipple
              disableRipple
              checked={formValue.showForManagers}
              onChange={handleCheckboxChange("showForManagers")}
              disabled={isManager}
            />
          }
          label="Manager Access"
        />
      </Box>
      <Box>
        {formValue.showForManagers && (
          <FormControlLabel
            className="disabledLabel"
            control={
              <Checkbox
                className="readOnlyCheckboxStyle"
                inputProps={{ "aria-label": "Manager Read-only" }}
                disableFocusRipple
                disableRipple
                checked={formValue.readOnly}
                onChange={handleCheckboxChange("readOnly")}
                disabled={isManager}
              />
            }
            label="Manager Read-only"
          />
        )}
      </Box>

      {/* Hours Config Table */}
      <Box className="fullWidth">
        <MainHoursConfigTable
          rowData={{}}
          toggleDialogOpen={toggleDialogOpen}
          memoDataset={memoDataset}
        />
      </Box>
    </Box>
  );
};

export default MainHoursAdd;
