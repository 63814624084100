import { useEffect, useState, useCallback } from "react";
import { IconButton, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { updateCallHistory } from "../../../../services/history";
import { updateCurrentCtr } from "../../../../services/current";
import SbuxTextField from "../../../../components/SubxTextField";
import {
  CURRENT_TICKET_OR_CASE_NUMBER,
  CURRENT_VAR_TICKET_OR_CASE_NUMBER,
} from "../../../../constants";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";
import { getInstanceIdSelector } from "../../../../selectors/userInstanceSelector";

const InputTableCell = ({ row, contactId, timeStamp }) => {
  const classes = useCss(styles);
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState(row);
  const [previous, setPrevious] = useState({});

  const instanceId = useSelector(getInstanceIdSelector);

  useEffect(() => {
    setPrevious(row);
  }, [row]);

  const { isEditMode } = tableRow;

  const handleChange = useCallback((e) => {
    const { value } = e.target;
    setTableRow((prevState) => ({
      ...prevState,
      value,
    }));
  }, []);

  const handleCancelMode = useCallback(() => {
    setTableRow({ ...previous, isEditMode: false });
  }, [previous]);

  const handleEditMode = useCallback(() => {
    setTableRow((prevState) => ({
      ...prevState,
      isEditMode: true,
    }));
  }, []);

  const handleSaveMode = useCallback(async () => {
    const ticketOrCaseNumberValue = tableRow.value;
    const updateHistoryData = {
      timestamp: timeStamp,
      updates: {
        [CURRENT_TICKET_OR_CASE_NUMBER]: ticketOrCaseNumberValue,
        [CURRENT_VAR_TICKET_OR_CASE_NUMBER]: ticketOrCaseNumberValue,
      },
    };
    setTableRow((prevState) => ({
      ...prevState,
      isEditMode: false,
    }));
    await dispatch(updateCallHistory(updateHistoryData));
    await dispatch(
      updateCurrentCtr({ instanceId, contactId, ticketOrCaseNumberValue })
    );
  }, [tableRow]);

  return (
    <>
      {isEditMode ? (
        <Box sx={{ display: "flex", width: 220 }}>
          <SbuxTextField
            value={tableRow.value}
            handleChange={handleChange}
            multiline={tableRow.value.length > 20}
            maxLength={100}
            displayCounter
          />
          <IconButton
            disabled={tableRow.value.trim() === ""}
            aria-label="save"
            color="primary"
            onClick={handleSaveMode}
          >
            <SaveIcon fontSize="medium" />
          </IconButton>
          <IconButton aria-label="cancel" onClick={handleCancelMode}>
            <CancelIcon fontSize="medium" />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: "flex", width: 220 }}>
          <Box className={classes.inputValue}>{tableRow.value}</Box>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={handleEditMode}
          >
            <EditIcon fontSize="medium" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default InputTableCell;
