import React, { useState, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Stack, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  DataGrid,
  GridToolbarQuickFilter,
  // GridLogicOperator,
  useGridApiRef,
  DEFAULT_GRID_AUTOSIZE_OPTIONS,
} from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "./SbuxGenericOverlay";

import { DATAGRID_DEFAULT_PAGE_SIZE } from "../../constants";
import { calcPageSizeOptions, mapRowIdentifier } from "../../utils/tableUtil";
import { useDebounce } from "../../utils/commonUtil";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { normalizeProps, getRowClasses, calcRowIdFn } from "./util";

const defaultPaginationModel = {
  pageSize: DATAGRID_DEFAULT_PAGE_SIZE,
  page: 0,
};

const defaultAutosizeOptions = {
  includeOutliers: true,
  includeHeaders: true,
  expand: true,
  outliersFactor: DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor,
};

const debounceMs = 250;

/**
 * SbuxGridToolbar toolbar component
 * @param {Object} props  component props
 */
export const SbuxGridToolbar = React.memo((props) => {
  const theme = useTheme();
  const classes = useCss(styles);

  const classList = [classes.gridToolbar];
  props.className && classList.push(props.className);
  const className = classList.join(" ");

  return (
    <>
      {createPortal(
        <Stack spacing={theme.spacing(1)} direction="row" className={className}>
          {props.children}
          {props.showQuickFilter !== false && (
            <GridToolbarQuickFilter
              debounceMs={debounceMs}
              variant="outlined"
            />
          )}
        </Stack>,
        document.getElementById("grid-toolbar")
      )}
    </>
  );
});

/**
 * GridContainer component that will cotain the instance of DataGrid and will call
 * a autosizeColumnsCallback function when the ResizeObserver detects a change
 * @param {Object} props  component props
 */
const GridContainer = (props) => {
  const boxRef = useRef(null);
  const callbackFn = useDebounce((entries) => {
    if (entries && typeof props.autosizeColumnsCallback === "function") {
      props.autosizeColumnsCallback();
      // NOTE: Conditional autosize can be done by considering entries[0].contentRect.width prev
      // and new value
    }
  }, 350);

  useLayoutEffect(() => {
    const targetEl = boxRef.current;

    if (!targetEl) return;

    const resizeObserver = new ResizeObserver(callbackFn);
    resizeObserver.observe(targetEl);

    return () => {
      resizeObserver.unobserve(targetEl);
    };
  });

  return (
    <Box ref={boxRef} className={props.className}>
      {props.children}
    </Box>
  );
};

export const NoRowsOverlay = () => (
  <CustomNoRowsOverlay>{"No data found"}</CustomNoRowsOverlay>
);

/**
 * SbuxGenericGrid component
 * @param {Object} props  component props
 */
export const SbuxGenericGrid = (props) => {
  const theme = useTheme();
  const apiRef = props.apiRef;

  const rowClassesMap = getRowClasses(props);

  // Important
  const normalProps = normalizeProps(props);

  const initPaginationModel =
    props.initialState?.pagination?.paginationModel ?? defaultPaginationModel;
  const autosizeOptions = {
    ...defaultAutosizeOptions,
    ...(props.autosizeOptions ?? {}),
  };
  const shouldTriggerAutoresize =
    autosizeOptions.includeHeaders || autosizeOptions.expand;

  /* Pagination specs seems to need to be kept in a component.state */
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);
  const [pageSizeOptions] = useState(
    calcPageSizeOptions(paginationModel.pageSize)
  );

  const dataset = mapRowIdentifier(props.rows);
  const getRowIdFn = calcRowIdFn(dataset[0]);

  /**
   * Invokes the DataGrid Api to resize the columns with the default configuration
   * Feature can be disabled via config
   */
  const autosizeColumns = shouldTriggerAutoresize
    ? () => {
        apiRef.current && apiRef.current.autosizeColumns(autosizeOptions);
      }
    : null;

  /**
   * Handles onPaginationModelChange event
   * @param  {Object} model                 new pagination model
   * @param  {Object} details               pagination details
   */
  const handleOnPaginationModelChange = (model, details) => {
    setPaginationModel(model);

    if (typeof props.onPaginationModelChange === "function") {
      props.onPaginationModelChange(model, details);
    }
  };

  return (
    <Stack spacing={theme.spacing(1)} direction="column">
      <Box
        id="grid-toolbar"
        className={props.slotClasses?.toolbar?.container}
      />

      <GridContainer
        className={props.slotClasses?.datagrid?.container}
        autosizeColumnsCallback={autosizeColumns}
      >
        <DataGrid
          /* Default configuration */
          slots={{
            toolbar: props.slots?.toolbar
              ? props.slots?.toolbar
              : SbuxGridToolbar,
            noRowsOverlay: NoRowsOverlay,
          }}
          slotProps={{
            pagination: {
              showFirstButton: true,
              showLastButton: true,
              ...(props.slotProps?.pagination ?? {}),
            },
          }}
          initialState={{
            ...props.initialState,
            pagination: {
              ...props.initialState?.pagination,
              paginationModel: paginationModel,
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: false,
                //quickFilterLogicOperator: GridLogicOperator.Or,
              },
            },
          }}
          pageSizeOptions={pageSizeOptions}
          paginationModel={paginationModel}
          onPaginationModelChange={handleOnPaginationModelChange}
          isRowSelectable={() => false}
          disableSelectionOnClick
          getRowClassName={(config) => {
            const idx = config.indexRelativeToCurrentPage % 2;
            return rowClassesMap.get(idx);
          }}
          disableColumnMenu
          rowHeight={32}
          autosizeOnMount
          autosizeOptions={autosizeOptions}
          /* props to override default configuration if contained it */
          {...normalProps}
          /* data related configuration */
          rows={dataset} // row identifier added
          getRowId={getRowIdFn}
          /* DataGrid Api */
          apiRef={apiRef}
        />
      </GridContainer>
    </Stack>
  );
};
