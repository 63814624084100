import { useEffect, useState, useRef, useMemo } from "react";
import { Box } from "@mui/material";
import SbuxCard from "../../../../../../components/SbuxCard";

import {
  SbuxGenericGrid,
  // SbuxGridToolbar,
} from "../../../../../../components/SbuxDataGrid/SbuxGenericGrid";

import { useSelector, useDispatch } from "react-redux";
import { GenesysRecordingsOverlay } from "./GenesysRecordingsOverlay";
import { transformDataFn, calcTotal } from "./util";
import {
  getGenesysRecordings,
  getResetGenesysRecordings,
} from "../../../../../../services/genesysRecordings";
import SbuxDataFilters from "../../../../../../components/SbuxDataFilters";
import { getGenesysConfig } from "../../../../../../selectors/userInstanceSelector";
import {
  getGenesysRecordingsDataSelector,
  getPageKeyTrailSelector,
} from "../../../../../../selectors/genesysRecordingsSelector";
import styles from "./styles";
import useCss from "../../../../../../hooks/useCss";

const GenesysRecordingsDataGrid = ({ columns, filterList }) => {
  const dispatch = useDispatch();
  const classes = useCss(styles);

  const [paginationSpec, setPaginationSpec] = useState({
    page: 0,
    pageSize: 25,
    rowCount: -1,
    totalRowCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const paginationMetaRef = useRef();
  const [rows, setRows] = useState([]);
  const [dataFilters, setDataFilters] = useState([]);

  const genesysRecordingsData = useSelector(getGenesysRecordingsDataSelector);
  const pageKeyTrail = useSelector(getPageKeyTrailSelector);
  const genesysConfig = useSelector(getGenesysConfig);

  const paginationModel = useMemo(
    () => ({
      ...paginationSpec,
    }),
    [paginationSpec]
  );

  const fetchData = async (pageKey, pageNumber, filters = void 0) => {
    if (!!genesysConfig?.callType) {
      const bodyArgs = {
        callType: genesysConfig?.callType,
        limit: paginationModel.pageSize,
        pageNumber: `${pageNumber}`,
        ...pageKey,
      };

      if (Array.isArray(filters) && filters.length > 0) {
        bodyArgs.filter = [...filters];
      }

      await dispatch(getGenesysRecordings(bodyArgs));
    }
  };

  useEffect(() => {
    const data = transformDataFn(genesysRecordingsData.data);
    setRows(data);

    // Calc to update the grid to whether enable "Next" button
    const { pageSize } = paginationModel;
    const { pageNumber = 0 } = genesysRecordingsData;
    const page = Number(pageNumber);

    const total = calcTotal(
      data.length,
      pageSize,
      page,
      !!genesysRecordingsData.currentEvaluatedKey
    );

    setIsLoading(false);
    setPaginationSpec((oldPage) => ({
      ...oldPage,
      page,
      totalRowCount: total,
    }));
  }, [genesysRecordingsData.data]);

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = useMemo(() => {
    const hasNextPage = !!genesysRecordingsData.currentEvaluatedKey;
    if (paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }

    return paginationMetaRef.current;
  }, [!!genesysRecordingsData.currentEvaluatedKey]);

  useEffect(() => {
    if (Array.isArray(dataFilters) && dataFilters.length > 0) {
      // call the backend
      fetchData({}, 0, dataFilters);
    }

    return () => {
      dispatch(getResetGenesysRecordings());
    };
  }, [genesysConfig?.callType]);

  /**
   * Handle page change event
   * @param  {number}  pageNumber     based on direction, it is the next or previous page number
   */
  const handlePaginationModelChange = async (newPaginationModel) => {
    const pageNumber = newPaginationModel.page;
    const { page: currentPage } = paginationModel;

    const pageKey = pageKeyTrail[pageNumber];

    // Prevent move fwd when no nextKey available
    if (pageNumber > currentPage && !pageKey) {
      return;
    }

    setIsLoading(true);

    await fetchData(pageKey, pageNumber, dataFilters);
  };

  /**
   * Handle click event to apply filter selection
   * @param  {Array}  filterSpec       list of filter descriptions
   */
  const handleApplyFilters = async (filterSpec) => {
    // Prep Date values in ISO format
    const filters = filterSpec.map((filter) => {
      const { value } = filter;
      if (typeof value.toISOString === "function") {
        return { ...filter, value: value.toISOString() };
      }

      return filter;
    });

    setDataFilters(filters);

    setIsLoading(true);
    // Clear up state storage
    await dispatch(getResetGenesysRecordings());

    // call the backend
    await fetchData({}, 0, filters);
  };

  return (
    <SbuxCard className="insideTabContainer">
      <SbuxDataFilters
        config={{ items: filterList }}
        onApplyFilters={handleApplyFilters}
      />

      <Box>
        <SbuxGenericGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          slotProps={{
            pagination: {
              labelDisplayedRows: () =>
                `${paginationModel.pageSize} rows per page`,
            },
          }}
          slots={{
            toolbar: undefined /* No use of toolbar due to re-renders */,
            noRowsOverlay: GenesysRecordingsOverlay,
          }}
          initialState={{
            pagination: {
              rowCount: -1,
              paginationModel: {
                page: paginationModel.page,
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          slotClasses={{
            datagrid: {
              container: classes.datagridContainer,
            },
          }}
          paginationModel={paginationModel}
          paginationMeta={paginationMeta}
          rowCount={paginationModel.totalRowCount}
          pageSizeOptions={[25]}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
        />
      </Box>
    </SbuxCard>
  );
};

export default GenesysRecordingsDataGrid;
