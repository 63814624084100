const styles = (theme) => ({
  inputButton: {
    height: 28,
    marginLeft: 15,
    width: 80,
    "&:focus": {
      outline: 0,
    },
  },
  input: {
    "& .MuiInput-input": {
      fontSize: 13,
    },
  },
  iconButton: {
    padding: "5px 2px",
    "&:focus": {
      outline: 0,
      borderRadius: 5,
    },
    "&:hover": {
      borderRadius: 5,
    },
  },
  iconsButton: {
    "&:focus": {
      outline: 0,
    },
    "&:hover": {
      outline: 0,
    },
  },
  actionContainer: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: 5,
    marginRight: 1,
    "& > button": {
      width: "auto",
      flexShrink: 0,
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  searchBarContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 6,
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    "& > .tabs": {
      width: "50%",
    },
    marginTop: 6,
  },
  valueCellContainer: {
    width: "80%",
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  pagination: {
    "& .MuiMenuItem-root": {
      "&:hover": {
        backgroundColor: theme.palette.darkGray[300],
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette.darkGray.main,
        color: "white",
        "&:hover": {
          backgroundColor: theme.palette.darkGray.main,
        },
      },
    },
  },
  dataNotFound: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 2,
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,

    "& > .actions": {
      paddingTop: 2,
      fontSize: 15,
      fontWeight: 600,
    },
  },
  tableFooter: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.white.main}`,
    backgroundColor: theme.palette.darkGray[200],
    position: "absolute",
    bottom: 0,
    height: 50,
    width: "100%",
  },
  sbuxLogo: {
    height: 120,
    width: 120,
    maxWidth: 120,
    maxHeight: 120,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "auto",
    },
  },
  cellCheckbox: {
    padding: "0 !important",
    color: `${theme.palette.white.main} !important`,
    "&.Mui-checked": {
      color: `${theme.palette.white.main} !important`,
    },
  },
  disabledContainer: {
    opacity: 0.5,
    pointerEvents: "none",
    userSelect: "none",
    cursor: "not-allowed !important",
  },
  enabledContainer: {
    opacity: 1,
    pointerEvents: "auto",
    userSelect: "auto",
    cursor: "auto",
  },
  selectAllColumn: {
    textAlign: "center !important",
    padding: "0 !important",
  },
});

export default styles;
